import React from 'react'
import CardSmall from '../../img/62fa8d2fe6c54d075cde81be_card-gold-rotated.svg';
import CardMid from '../../img/CardBlack.svg';
import CardBig from '../../img/CardGreen.svg';
import {AiOutlineArrowRight} from 'react-icons/ai'

function Landing() {
    return (
        <div className='ftrlanding'>
            <h6 className="ftrlanding__tophead">Features</h6>
            <h2 className="ftrlanding__header">All in one Card</h2>
            <p className="ftrlanding__text">
                Introducing our all-in-one card service - the secure, hassle-free way to manage all your
                financial transactions with a single card.
            </p>
            <div className="ftrlanding__btnbox">
                <button className='btn ftrlanding__btn--open'>Open Account</button>
                <button className='btn ftrlanding__btn--compare'>
                    <span>Compare Cards </span>
                    <AiOutlineArrowRight className='btn__arrow'/>
                </button>
            </div>
            <div className="ftrlanding__cardsection">
                <div className="ftrlanding__card ftrlanding__card--1">
                    <img src={CardSmall} alt="Feature landing cards illustration" className="ftrlanding__card__img ftrlanding__card__img--1" />
                </div>

                <div className="ftrlanding__card ftrlanding__card--2">
                    <img src={CardMid} alt="feature landing cards illustration" className="ftrlanding__card__img ftrlanding__card__img--2" />
                </div>
                
                <div className="ftrlanding__card ftrlanding__card--3">
                    <img src={CardBig} alt="feature landing cards illustration" className="ftrlanding__card__img ftrlanding__card__img--3" />
                </div>

                <div className="ftrlanding__card ftrlanding__card--4">
                    <img src={CardMid} alt="feature landing cards illustration" className="ftrlanding__card__img ftrlanding__card__img--4" />
                </div>

                <div className="ftrlanding__card ftrlanding__card--5">
                    <img src={CardSmall} alt="Feature landing cards illustration" className="ftrlanding__card__img ftrlanding__card__img--5" />
                </div>
            </div>
        </div>
    )
}


export default Landing
import React from 'react'
import Rodal from 'rodal';
import { isMobile } from 'react-device-detect';
import { BiTransferAlt, BiBox } from 'react-icons/bi'

// include styles
import 'rodal/lib/rodal.css';
import { TransactionItem } from './Dashboard';

function AllTransactionsModal(props) {
    return (
        <div>
            <Rodal
                visible={props.visible}
                onClose={() => props.setVisible(false)}
                height={600}
                width={isMobile ? window.innerWidth * 0.85 : 450}
            >
                <div className="home__dashboard__card__transactions__body"
                    style={{ overflowY: 'scroll', msOverflowY: 'scroll', backgroundColor: 'white', height: '570px', margin: '5px' }}>
                    <div className="home__dashboard__card__transactions__head">
                        <h5 className="home__dashboard__card__transactions__head__title">All Transactions</h5>
                        <BiTransferAlt className="home__dashboard__card__transactions__head__icon" />
                    </div>
                    {
                        props.transactionArray.map((item, value) => (
                            <div className='home__dashboard__card__transactions__itemcontainer'>
                           
                            <TransactionItem
                                data={item}
                            />
                            <TransactionDetails 
                                data={item}
                            />
                            </div>
                        ))
                    }

                    <button
                        className="btn home__dashboard__card__transactions__btn"
                        onClick={() => props.setVisible(false)}
                        style={{ width: '50%', margin: '1.5rem 0' , alignSelf: 'center'}}
                    >
                        Close
                    </button>

                </div>
            </Rodal>
        </div>
    )
}

const TransactionDetails = (props) => {
    return (
        <ul className="home__dashboard__card__transactions__fulllist">
            <li>
                <p>{props.data.type === 1 ? 'Source' : 'Destination' }</p>
                <span>{props.data.src}</span>
            </li>
            <li>
            <p>Transaction ID</p>
                <span>{props.data.ID || ''}</span>
            </li>
            <li>
            <p>Status</p>
                <span>{props.data.status || ''}</span>
            </li>
            <li>
            <p>Description</p>
                <span>{props.data.desc || ''}</span>
            </li>
        </ul>
    )
}


export default AllTransactionsModal
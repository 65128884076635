import React from 'react'
import Rodal from 'rodal';
import Usage from './Usage';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;

function FooterModal(props) {


    return (
        <div className='myrodal__container'>
            <Rodal
                visible={props.visible}
                onClose={() => props.setVisible(false)}
                width={deviceWidth > 425 ?  deviceWidth * 0.6 : deviceWidth * 0.95}
                height={720}
                closeMaskOnClick={false}

            >

                <div className='myrodal'>
                    <h2>
                        {props.current === 0 ? Usage.Terms.header : props.current === 1 ? Usage.Privacy.header : Usage.Copyright.header}
                    </h2>
                    <p>
                        {props.current ? Usage.Terms.text : props.current === 1 ? Usage.Privacy.text : Usage.Copyright.text}
                    </p>

                    <button
                        className="myrodal__btn btn"
                        onClick={() => props.setVisible(false)}
                    >Close</button>
                </div>
            </Rodal>
        </div>
    )
}

export default FooterModal
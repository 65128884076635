import React from 'react'
import Phone1 from '../../img/services_phone2.png'
import { AiOutlineThunderbolt } from 'react-icons/ai'
import { MdOutlineSavings } from 'react-icons/md'
import { VscGraph } from 'react-icons/vsc'
import { BsCreditCard2Front, BsPhone } from 'react-icons/bs'
import { IoMdWifi } from 'react-icons/io'

function Services() {
    return (
        <div className='services' data-aos="fade-up">
            <div className="services__content">
                <h2 className="services__content__header">One app. <br />One banking</h2>
                <div className="services__content__list">

                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <AiOutlineThunderbolt className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Instant Transactions</h3>
                        <p className="services__content__list__item__text">
                            Clients have access to Lightning-fast and seamless money transfers, providing customers with unparalleled convenience and reliability.
                        </p>

                    </div>

                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <MdOutlineSavings className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Savings Account</h3>
                        <p className="services__content__list__item__text">
                            Enjoy competitive interest rates and flexible deposit options providing customers with a convenient way to save for their future.
                        </p>

                    </div>



                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <BsPhone className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Mobile Banking</h3>
                        <p className="services__content__list__item__text">
                            Secure authentication features access to a wide range of services allowing customers to manage their finances anytime, anywhere, from the palms.
                        </p>

                    </div>

                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <VscGraph className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Advanced Statistics</h3>
                        <p className="services__content__list__item__text">
                            Powerful data analysis tools and comprehensive insights, enabling easy informed decisions and a competitive edge in their industry.
                        </p>

                    </div>

                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <BsCreditCard2Front className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Virtual Cards</h3>
                        <p className="services__content__list__item__text">
                            Secure and convenient way to make online purchases, with instant issuance and customizable spending limits, providing customers with enhanced control  for their transactions.
                        </p>

                    </div>

                    <div className="services__content__list__item">
                        <div className="services__content__list__item__labelbox">
                            <IoMdWifi className='services__content__list__item__label' />
                        </div>
                        <h3 className="services__content__list__item__header">Contactless Payments</h3>
                        <p className="services__content__list__item__text">
                            Fast, secure, and hygienic transactions, enabling customers to conveniently tap their card or mobile device at the point of sale, reducing wait times and minimizing physical contact.
                        </p>

                    </div>

                </div>
            </div>

            <div className="services__imgsection">
                <div className="services__imgsection__imgbox">
                    <img src={Phone1} alt="" className="services__imgsection__img" />
                </div>
            </div>
        </div>
    )
}

export default Services
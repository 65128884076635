import React from 'react'
import { IoMdCheckmarkCircle } from 'react-icons/io'

import Shopify from '../../img/62f4da71bbbfc1097761a209_logo-shopify.svg'
import Zapier from '../../img/62f4da71fe7a4e4e84349e69_logo-zapier.svg'
import Paypal from '../../img/62f4da701cd4267cfc73f487_logo-paypal.svg'
import MasterCard from '../../img/62f4da7004e0670686d84208_logo-mastercard.svg';
import VisaCard from '../../img/62f4da70ea037032fa4c9c44_logo-visa.svg'
import ApplePay from '../../img/62f4da70af7b5be93b24db52_logo-apple-pay.svg';
import Gpay from '../../img/62f4da704f9c700dbbe3fe58_logo-gpay.svg';
import AmazonPay from '../../img/62f4da71764027350203ea77_logo-amazon-pay.svg';
import Btc from '../../img/62f4da717ff2dd04ad03cabc_logo-bitcoin.svg'

function Integrate() {

    return (
        <div className='integrate'>
            <div className="integrate__partners" id="integrate-partners" data-aos="fade-up">
                <div className="<integrate__partners__item>"><img src={Shopify} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={Zapier} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={Btc} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={Paypal} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={MasterCard} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={VisaCard} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={ApplePay} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={Gpay} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
                <div className="<integrate__partners__item>"><img src={AmazonPay} alt="Partners Logo" className="integrate_partners__item__logo" /></div>
            </div>

            <div className="integrate__tools">
                <div className="integrate__tools__content" data-aos="fade-up">
                    <div className="integrate__tools__tophead">Tools</div>
                    <div className="integrate__tools__header">Seemless integration</div>
                    <p className="integrate__tools__text">
                        Our seamless integration with our colleagues at banquee enables us to provide you a greater selection of beneficial services
                        to aid in the achievement of your financial objectives.
                    </p>
                </div>

                <div className="integrate__tools__list" data-aos="fade-up">
                    <div className="integrate__tools__list__item">
                        <IoMdCheckmarkCircle className='integrate__tools__list__item__label' />
                        <span>Broadens services for customers.</span>
                    </div>

                    <div className="integrate__tools__list__item">
                        <IoMdCheckmarkCircle className='integrate__tools__list__item__label' />
                        <span>Enhances & improves experience.</span>
                    </div>

                    <div className="integrate__tools__list__item">
                        <IoMdCheckmarkCircle className='integrate__tools__list__item__label' />
                        <span>Personalized solutions to meet needs.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integrate
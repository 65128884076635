import React from 'react'
import Chatus from '../../img/62fa5d96130e5641a597a446_app-support.svg'

function Landing() {
    return (
        <div className='sptlanding'>
            <div className="sptlanding__header">
                <h6 className="sptlanding__header__sub">Support</h6>
                <h2 className="sptlanding__header__main">How can we help you?</h2>
            </div>

            <div className="sptlanding__cardbox">

                <div className="sptlanding__card__imgbox">
                    <img src={Chatus} alt="Item Representation" className="sptlanding__card__img" />
                </div>

            </div>
        </div>
    )
}

export default Landing
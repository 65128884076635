import React, { Component } from 'react'
import Landing from './Landing'
import { Header } from '../../_shared'
import MainFeatures from './MainFeatures'
import OneBank from './OneBank'
import Cards from './Cards';
import { Footer } from '../../_shared'

import { MobileBanking, Help, Blog} from '../Landing'
import ChatHelpBot from '../../chatbot/Chatbot'
export class AppFeatures extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header />
        <Landing />
        <MainFeatures />
        <OneBank />
        <Cards />
        <MobileBanking />
        <Help />
        <Blog />
        
        <Footer />

        <ChatHelpBot />
        
      </div>
    )
  }
}

export default AppFeatures
import Types from './Types';

export const setCurrentUser = (userData) => {
    return {
        type: Types.SET_CURRENT_USER,
        payload: userData,
    }
}

export const setShowChatbot = () => {
    return {
        type: Types.SET_CHATBOT,
    }
}
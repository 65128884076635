import React, { useEffect } from 'react'
import Transaction from '../../img/62fa8d89f4b68721cb70871e_app-screen-home.webp';
import Cards from '../../img/62fa8d872edb2efc430f3c3a_app-screen-cards.webp';
import Stats from '../../img/62f6bed19570fefff1dcacc4_app-screen-stats.webp';
import Account from '../../img/62f6bed19570fefc13dcacc3_app-screen-savings.webp';
import { IoMdCheckmarkCircle } from 'react-icons/io'

const featureData = [
    {
        head: 'Transactions',
        mainheading: 'Send & receive money instantly',
        content: 'Experience quick and secure money transfers with our quality instant money transfer service. Our user-friendly platform and advanced security measures ensure your money is in safe hands.Sign up now to enjoy the speed and convenience of instant money transfers anytime, anywhere.',
        points: ['Low fees and transparent pricing', 'Advanced security measures', '24/7 customer support']
    },

    {
        head: 'Cards',
        mainheading: 'Manage Your Cards',
        content: 'Our card management service offers convenient features for easy card management. From checking your account balance to reporting lost or stolen cards, our service puts you in control. Sign up now for peace of mind.',
        points: ['Real-time card transaction alerts.', 'Ability to lock and unlock cards instantly.', 'Option to customize card usage controls.']
    },
    {
        head: 'Advanced Statistics',
        mainheading: 'Keep control of your money',
        content: 'Our Advanced Statistics service provides valuable insights into your financial data. From tracking spending patterns to identifying investment opportunities, our tools give you the information you need to make informed decisions. Sign up now to start exploring data-driven insights.',
        points: ['Interactive visualizations for easy data interpretation.', 'Historical trends and forecasting capabilities.', 'Secure and confidential data storage.']
    },
    {
        head: 'Saving Account',
        mainheading: 'Save for your dream vacation',
        content: 'Welcome to our Savings Account service! Ready to turn your dreams into reality? Our specialized savings packages can help you save for that dream vacation, a new car, or other major expenses. With competitive interest rates and flexible terms, our packages are tailored to fit your needs.',
        points: ['Specialized savings packages.', 'Ability to track your progress towards your savings goals.', 'No monthly maintenance fees.']
    }

]

function MainFeatures() {

    useEffect(() => {
        _scrollEffect()
        return () => window.removeEventListener('scroll', _handleScroll)
    }, [])


    function _scrollEffect() {




        // update position of square 1 and square 2 when scroll event fires.
        window.addEventListener('scroll', _handleScroll)
    }

    function _handleScroll() {
        const container = document.getElementById('mainfeatures');
        const windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let scrollArea = 1000 - windowHeight;
        let lastScroll = window.pageYOffset;
        let imgs = document.getElementsByClassName('mainfeatures__imgswipe__img');

        let moveSpeed = 0.390244;
        let features = document.getElementsByClassName('mainfeatures__feature');
        var scrollTop = window.pageYOffset;

        var scrollPercent = scrollTop / scrollArea || 0;
        

        // img1.style.left = scrollPercent * window.innerWidth + 'px';
        //  console.log(scrollPercent * 20)
        // img1.style.right = (scrollPercent * 90) + 'px';
        // console.log((scrollTop * moveSpeed))

        switch (Math.floor((scrollTop) / windowHeight)) {
            case 1:
                //console.log('img2')
                imgs[1].style.transform = `translateX(${320 - ((scrollTop - (1 * windowHeight)) * moveSpeed)}px)`
                break;

            case 2:
                //console.log('img3--->')
                imgs[2].style.transform = `translateX(${320 - ((scrollTop - (2 * windowHeight)) * moveSpeed)}px)`
                break;

            case 3:
                //console.log('img4')
                imgs[3].style.transform = `translateX(${320 - ((scrollTop - (3 * windowHeight)) * moveSpeed)}px)`
                break;


            default:
                break;
        }
    }

    function isScrolledIntoView(el) {
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        let elemBottom = rect.bottom;

        // Only completely visible elements return true:
        // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // Partially visible elements return true:
        let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }

    function isScrolledPastView(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;

        // Only completely scrolled past elements return true:
        var isScrolledPast = (elemTop <= 0);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isScrolledPast;
    }

    return (
        <div className='mainfeatures'>
            <div className="mainfeatures__laptop">
                <div className="mainfeatures__content">
                    <FeatureItem data={featureData[0]} />
                    <FeatureItem data={featureData[1]} />
                    <FeatureItem data={featureData[2]} />
                    <FeatureItem data={featureData[3]} />
                </div>

                <div className="mainfeatures__imgswipe">
                    <div className="mainfeatures__imgswipe__container">
                        <img src={Transaction} alt="Screen Representation" className="mainfeatures__imgswipe__img mainfeatures__imgswipe__img--1" />
                        <img src={Cards} alt="Screen Representation" className="mainfeatures__imgswipe__img mainfeatures__imgswipe__img--2" />
                        <img src={Stats} alt="Screen Representation" className="mainfeatures__imgswipe__img mainfeatures__imgswipe__img--3" />
                        <img src={Account} alt="Screen Representation" className="mainfeatures__imgswipe__img mainfeatures__imgswipe__img--4" />

                    </div>
                </div>
            </div>

            <div className="mainfeatures__phone">
                <PhoneFeatureItem data={featureData[0]} >
                    <img src={Transaction} alt="Screen Representation" className="mainfeatures__phone__feature__imgswipe__img" />
                </PhoneFeatureItem>
                <PhoneFeatureItem data={featureData[1]} >
                    <img src={Cards} alt="Screen Representation" className="mainfeatures__phone__feature__imgswipe__img" />
                </PhoneFeatureItem>
                <PhoneFeatureItem data={featureData[2]} >
                    <img src={Stats} alt="Screen Representation" className="mainfeatures__phone__feature__imgswipe__img" />
                </PhoneFeatureItem>
                <PhoneFeatureItem data={featureData[3]} >
                    <img src={Account} alt="Screen Representation" className="mainfeatures__phone__feature__imgswipe__img" />
                </PhoneFeatureItem>
            </div>
        </div>
    )
}

const FeatureItem = (props) => {
    return (
        <div className="mainfeatures__feature">
            <h2 className="mainfeatures__feature__tophead">{props.data.head}</h2>
            <h2 className="mainfeatures__feature__header">{props.data.mainheading}</h2>
            <p className="mainfeatures__feature__text">{props.data.content}</p>

            <ul className="mainfeatures__feature__list">
                {
                    props.data.points.map((value) => (
                        <li>
                            <IoMdCheckmarkCircle className='mainfeatures__feature__list__label' />
                            <span>{value}</span>

                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

const PhoneFeatureItem = (props) => {
    return (
        <div className="mainfeatures__phone__feature">
            <div className="mainfeatures__phone__feature__imgswipe">
                {props.children}
            </div>
            <h2 className="mainfeatures__phone__feature__tophead">{props.data.head}</h2>
            <h2 className="mainfeatures__phone__feature__header">{props.data.mainheading}</h2>
            <p className="mainfeatures__phone__feature__text">{props.data.content}</p>

            <ul className="mainfeatures__phone__feature__list">
                {
                    props.data.points.map((value) => (
                        <li>
                            <IoMdCheckmarkCircle className='mainfeatures__phone__feature__list__label' />
                            <span>{value}</span>

                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default MainFeatures
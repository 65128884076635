import React, { Children } from 'react'
import { BsApple, BsCheckCircleFill, BsTwitter, BsDropbox, BsPaypal } from 'react-icons/bs'
import { TfiMicrosoftAlt } from 'react-icons/tfi'
import { RiNetflixFill, RiAmazonFill } from 'react-icons/ri'

function InstantMoney() {
    return (
        <div className='instantmoney'>

            <div className="instantmoney__content" data-aos="fade-up">
                <div className="instantmoney__content__header">
                    Send & receive money instantly
                </div>

                <p className="instantmoney__content__text">
                    Our instant transaction service provides customers with lightning-fast and reliable money transfers,
                    ensuring seamless and convenient transactions that meet the demands of today's fast-paced digital world.
                </p>

                <ul className="instantmoney__content__text__list">
                    <li className="instantmoney__content__text__list__item">
                        <BsCheckCircleFill className="instantmoney__content__text__list__item__bullet" />
                        <span>Money transfers completed in seconds.</span>

                    </li>
                    <li className="instantmoney__content__text__list__item">
                        <BsCheckCircleFill className="instantmoney__content__text__list__item__bullet" />
                        <span>Efficient processing for large volumes.</span>

                    </li>
                    <li className="instantmoney__content__text__list__item">
                        <BsCheckCircleFill className="instantmoney__content__text__list__item__bullet" />
                        Instant availability of transferred funds.

                    </li>
                </ul>

            </div>

            <div className="instantmoney__receipt" data-aos="fade-up">
                <div className="instantmoney__receipt__container">
                    <ReceiptItem
                        mainText="Apple"
                        subText="Macbook"
                        price="-999"
                    >
                        <BsApple className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Amazon"
                        subText="Electronics"
                        price="-49"
                    >
                        <RiAmazonFill className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Twitter"
                        subText="Ads"
                        price="-29"
                    >
                        <BsTwitter className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Microsoft"
                        subText="Office Suite"
                        price="-149"
                    >
                        <TfiMicrosoftAlt className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Dropbox"
                        subText="Cloud"
                        price="-14"
                    >
                        <BsDropbox className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Paypal"
                        subText="Shopping"
                        price="-250"
                    >
                        <BsPaypal className="instantmoney__receipt__item__img" />
                    </ReceiptItem>

                    <ReceiptItem
                        mainText="Netflix"
                        subText="Subscription"
                        price="-19"
                    >
                        <RiNetflixFill className="instantmoney__receipt__item__img" />
                    </ReceiptItem>


                </div>


            </div>
        </div>
    )
}


const ReceiptItem = (props) => {
    //console.log(props.children)
    return (
        <div className="instantmoney__receipt__item">
            <div className="instantmoney__receipt__item__labeltextbox">
                <div className="instantmoney__receipt__item__label">
                    {props.children} {/*  Only render icon in Receipt item declared inside the main jsx return function */}
                </div>
                <div className="instantmoney__receipt__item__textbox">
                    <p className="instantmoney__receipt__item__textbox__main">{props.mainText}</p>
                    <p className="instantmoney__receipt__item__textbox__sub">{props.subText}</p>
                </div>
            </div>
            <div className="instantmoney__receipt__item__price">
                <p className="instantmoney__receipt__item__price__value">{props.price}<span> &euro;</span></p>
            </div>

        </div>
    )
}

export default InstantMoney;
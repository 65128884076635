import './sass/main.scss';
import Navigator from './Navigator';
import WebFont from 'webfontloader'
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();
  
  WebFont.load({
    google: {
      families: ['Nunito', 'Sora']
    }
  });
  
  return (
    <div className="App">
      <Navigator />
    </div>
  );
}

export default App;

import React from 'react'
import Chatbot from 'react-chatbot-kit'
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './Config';
import BotImg from '../img/chatbot.png';
import { connect } from 'react-redux';
import { setShowChatbot } from '../redux/actions'
import { useDispatch } from "react-redux";

function ChatHelpBot(props) {

    const dispatch = useDispatch();

    return (
        <div className='mychatbot'>
            {
                props.visible && (
                    <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser} />
                ) 
            }
            <img src={BotImg} alt="Chatbot Icon" className="mychatbot__label" onClick={() => dispatch(setShowChatbot())} />



        </div>
    )
}

const mapStateToProps =(state) => ({
    visible: state.showChatbot,
})

export default connect(mapStateToProps)(ChatHelpBot)
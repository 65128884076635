import React, { useEffect } from 'react'
import { Header, Footer, ContactUs } from '../../_shared'
import Landing from './Landing';
import CardTable from './CardTable';

function Compare() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div className='cmp'>
      <Header />
      <Landing />
      <CardTable />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Compare
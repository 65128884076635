import React, { Component } from 'react'
import { Header, Footer } from '../../_shared'
import Form from './Form'
import { Help } from '../Landing'

class Signup extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <Header />
                <Form />
                <Help />
                <Footer />
            </div>
        )
    }
}


export default Signup
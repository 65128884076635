import React from 'react'
import { CardItem } from '../AppFeatures/Cards'
import CardBlack from '../../img/CardBlackB.svg';
import CardGreen from '../../img/CardGreenB.svg';
import CardGold from '../../img/CardGold.svg'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { AiOutlineCreditCard, AiFillCreditCard } from 'react-icons/ai'
import { BsCreditCard, BsGraphUp } from 'react-icons/bs'
import { MdContactless, MdAtm } from 'react-icons/md'
import { FaMobile, FaGlobe } from 'react-icons/fa'
import { GiReceiveMoney, GiPiggyBank } from 'react-icons/gi'
import { SiLinktree } from 'react-icons/si'

const cardData = [
  {
    name: 'Basic',
    price: 'Free',
    description: 'For single individuals such as students and managing parts of a company structure'
  },
  {
    name: 'Premium',
    price: '5$',
    description: 'For small scale business and joint accounts for individuals whether couple or not'
  },
  {
    name: 'Gold',
    price: '10$',
    description: 'Suitable for large scale business and companies who  offer global services'
  }
]

const CardHeaders = [
  'Physical Card',
  'Virtual Card',
  'Contactless Payments',
  'Mobile Payments',
  'Free Payments Worldwide',
  'Free ATM Withdrawals',
  'Mobile Banking',
  'Saving Accounts',
  'Advanced Statistics',
  'Premium Parnter Offers',
]

const CardHeaderDescription = [
  'Availability for ATM withdrawals',
  'Availability for online purchases',
  'Tap or Scan to pay',
  'Transfer money from USSD',
  'Payment channels at zero rates',
  'Withdraw money for no charge',
  'Anytime anywhere access',
  'Dream, we make it happen',
  'User friendly visualation tools',
  'Connect our partners worldwide',
]

const HeaderIcons = [
  <AiFillCreditCard className='cardtable__label__img' />,
  <BsCreditCard className='cardtable__label__img' />,
  <MdContactless className='cardtable__label__img' />,
  <GiReceiveMoney className='cardtable__label__img' />,
  <FaGlobe className='cardtable__label__img' />,
  <MdAtm className='cardtable__label__img' />,
  <FaMobile className='cardtable__label__img' />,
  <GiPiggyBank className='cardtable__label__img' />,
  <BsGraphUp className='cardtable__label__img' />,
  <SiLinktree className='cardtable__label__img' />
]

const Tier1 = [

  'Optional',
  '-',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '-',
  '2',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '2',
  '-',
  '-'
]

const Tier2 = [

  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  'Up to 2',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '5',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '5',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '-',
]

const Tier3 = [

  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  'Unlimited',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  '10',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  'Unlimited',
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
  <IoMdCheckmarkCircle className='cardtable__checkmark' />,
]

function CardTable() {
  return (
    <div className='cardtable'>

      <table className='cardtable__table'>
        <tr className='cardtable__table__imglabelbox'>
          <th></th>
          <th>
            <CardItem data={cardData[0]} hasHeaderTag img={CardGreen} />
          </th>
          <th>
            <CardItem data={cardData[1]} isPricedMonthly img={CardBlack} />
          </th>
          <th>
            <CardItem data={cardData[2]} isPricedMonthly img={CardGold} />
          </th>
        </tr>

        <tr>
          <td className='cardtable__sectionhead'>Credit Card</td>
        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Physical Card"
              description="Availability for ATM withdrawals"
            >
              <AiFillCreditCard className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>Optional</td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Virtual Card"
              description="Availability for online purchases"
            >
              <BsCreditCard className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>-</td>
          <td>Up to 2</td>
          <td>
            Unlimited
          </td>

        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Contactless Payments"
              description="Tap or Scan to pay"
            >
              <MdContactless className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

        <tr className='cardtable__noborderbottom'>
          <td>
            <LabelItem
              labelText="Mobile Payments"
              description="Transfer money from USSD"
            >
              <GiReceiveMoney className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

        <tr>
          <td className='cardtable__sectionhead'>Bank Account</td>
        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Free Payments Worldwide"
              description="Payment channels at zero rates"
            >
              <FaGlobe className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>-</td>
          <td>-</td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Free ATM Withdrawals"
              description="Withdraw money for no charge"
            >
              <MdAtm className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>2</td>
          <td>5</td>
          <td>10</td>

        </tr>

        <tr className='cardtable__noborderbottom'>
          <td>
            <LabelItem
              labelText="Mobile Banking"
              description="Anytime anywhere access"
            >
              <FaMobile className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

        <tr>
          <td className='cardtable__sectionhead'>Extra Features</td>
        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Savings Accounts"
              description="Dream, we make it happen"
            >
              <GiPiggyBank className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>2</td>
          <td>5</td>
          <td>unlimted</td>
        </tr>

        <tr>
          <td>
            <LabelItem
              labelText="Advanced Statistics"
              description="User friendly visualation tools"
            >
              <BsGraphUp className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>-</td>
          <td><IoMdCheckmarkCircle className='cardtable__checkmark' /></td>
          <td><IoMdCheckmarkCircle className='cardtable__checkmark' /></td>

        </tr>

        <tr className='cardtable__noborderbottom'>
          <td>
            <LabelItem
              labelText="Premium Partner Offers"
              description="Connect our partners worldwide"
            >
              <SiLinktree className='cardtable__label__img' />
            </LabelItem>
          </td>
          <td>-</td>
          <td>-</td>
          <td>
            <IoMdCheckmarkCircle className='cardtable__checkmark' />
          </td>

        </tr>

      </table>


      <div>
        <CardPhoneTable />
      </div>
    </div>
  )
}

const CardPhoneTable = (props) => {
  return (
    <div className="cardtable__phone">
      <div className="cardtable__phone__container">

        <div className="cardtable__phone__tier">
          <CardItem data={cardData[0]} hasHeaderTag img={CardGreen} />
          <div className="cardtable__phone__tier__subheader">Credit Card</div>
          {
            Tier1.slice(5).map((item, index) => (

              <div className="cardtable__phone__tier__item">

                <PhoneLabelItem
                  labelText={CardHeaders[index]}
                  description={CardHeaderDescription[index]}
                  img={HeaderIcons[index]}
                />

                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Bank Account</div>
          {
            Tier1.slice(5, 8).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[5 + index]}
                  description={CardHeaderDescription[5 + index]}
                  img={HeaderIcons[5 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Extra Features</div>
          {
            Tier1.slice(8, 10).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[8 + index]}
                  description={CardHeaderDescription[8 + index]}
                  img={HeaderIcons[8 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }
        </div>


        <div className="cardtable__phone__tier">
          <CardItem data={cardData[2]} img={CardBlack} />
          <div className="cardtable__phone__tier__subheader">Credit Card</div>
          {
            Tier2.slice(5).map((item, index) => (

              <div className="cardtable__phone__tier__item">

                <PhoneLabelItem
                  labelText={CardHeaders[index]}
                  description={CardHeaderDescription[index]}
                  img={HeaderIcons[index]}
                />

                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Bank Account</div>
          {
            Tier2.slice(5, 8).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[5 + index]}
                  description={CardHeaderDescription[5 + index]}
                  img={HeaderIcons[5 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Extra Features</div>
          {
            Tier2.slice(8, 10).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[8 + index]}
                  description={CardHeaderDescription[8 + index]}
                  img={HeaderIcons[8 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }
        </div>


        <div className="cardtable__phone__tier">
          <CardItem data={cardData[2]} img={CardGold} />
          <div className="cardtable__phone__tier__subheader">Credit Card</div>
          {
            Tier3.slice(5).map((item, index) => (

              <div className="cardtable__phone__tier__item">

                <PhoneLabelItem
                  labelText={CardHeaders[index]}
                  description={CardHeaderDescription[index]}
                  img={HeaderIcons[index]}
                />

                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Bank Account</div>
          {
            Tier3.slice(5, 8).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[5 + index]}
                  description={CardHeaderDescription[5 + index]}
                  img={HeaderIcons[5 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }

          <div className="cardtable__phone__tier__subheader">Extra Features</div>
          {
            Tier3.slice(8, 10).map((item, index) => (
              <div className="cardtable__phone__tier__item">
                <PhoneLabelItem
                  labelText={CardHeaders[8 + index]}
                  description={CardHeaderDescription[8 + index]}
                  img={HeaderIcons[8 + index]}
                />
                <div className='cardtable__phone__tier__item__value'>{item}</div>
              </div>

            ))
          }
        </div>

      </div>
    </div>

  )
}

const LabelItem = (props) => {
  return (
    <div className="cardtable__label">
      <div className="cardtable__label__imgbox">
        {props.children}
      </div>
      <div className="cardtable__label__textbox">
        <h3 className="cardtable__label__title">{props.labelText}</h3>
        <p className="cardtable__label__description">{props.description}</p>
      </div>
    </div>
  )
}

const PhoneLabelItem = (props) => {
  //console.log(props)
  return (
    <div className="cardtable__label">
      <div className="cardtable__label__imgbox">
        {props.img}
      </div>
      <div className="cardtable__label__textbox">
        <h3 className="cardtable__label__title">{props.labelText}</h3>
        <p className="cardtable__label__description">{props.description}</p>
      </div>
    </div>
  )
}

export default CardTable;

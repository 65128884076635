import React, { useEffect } from 'react'
import Logo from '../img/logoimg.png';
import { useNavigate } from 'react-router-dom'

function Footer(props) {

    const  navigate = useNavigate()
    const Partners = document.getElementById('integrate-partners');
    // console.log(Integrate)


    const displayRodal = (index) => {
        props.setCurrentModal(index)
        props.setModal(true)
    }


    function findPosition(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
          do {
            currenttop += obj.offsetTop;
          } while ((obj = obj.offsetParent));
          return [currenttop-80];
        }
      }

    return (
        <div className='footer'>

            <div className="footer__main">

                <div className="footer__logobox">
                    <img src={Logo} alt="Company Logo" className="footer__logo" />
                </div>

                <div className="footer__about">
                    <h4 className="footer__subhead">About Us</h4>
                    <ul className="footer__subcontainer">
                        <li onClick={() => navigate('/features')}>Features</li>
                        <li onClick={() => navigate('/compare')}>Compare</li>
                        <li onClick={() => navigate('/')}>About Us</li>
                        <li onClick={() => navigate('/support')}>Contact Us</li>
                    </ul>
                </div>

                <div className="footer__blog">
                    <h4 className="footer__subhead">Blog</h4>
                    <ul className="footer__subcontainer">
                        <li onClick={() => navigate('/blog')}>Technology</li>
                        <li onClick={() => navigate('/blog')}>Product</li>
                        <li onClick={() => navigate('/blog')}>App</li>
                        <li onClick={() => navigate('/blog')}>Finance</li>
                    </ul>
                </div>
                <div className="footer__accounts">
                    <h4 className="footer__subhead">Account</h4>
                    <ul className="footer__subcontainer">
                        <li>Current Account</li>
                        <li>Savings Account</li>
                        <li>Offshore Account</li>
                        <li>Checking Account</li>
                    </ul>
                </div>

                <div className="footer__quicklinks">
                    <h4 className="footer__subhead">Quicklinks</h4>
                    <ul className="footer__subcontainer">
                        <li onClick={() => navigate('/login')}>Login</li>
                        <li onClick={() => navigate('/signup')}>Open Account</li>
                        <li onClick={() => navigate('/support')}>FAQs</li>
                        <li onClick={() => window.scroll(0, findPosition(document.getElementById("integrate-partners")))}>Partners</li>
                    </ul>
                </div>

                <div className="footer__statements">
                    <h4 className="footer__subhead">Resources</h4>
                    <ul className="footer__subcontainer">
                        <li onClick={() => displayRodal(0)}>Terms of Use</li>
                        <li onClick={() => displayRodal(1)}>Privacy Policy</li>
                        <li onClick={() => displayRodal(2)}>Copyright</li>
                        <li onClick={() => window.scrollTo(0, 0)}>Back to Top</li>
                    </ul>
                </div>
            </div>

            <div className="footer__sub">
                <div className="footer__sub__copyright">
                    <p>&copy; &nbsp;  All Rights Reserved - Powered by eindhoveninvestment.com</p>
                </div>
                <div className="footer__sub__read">
                    <p
                        onClick={() => displayRodal(0)}
                    >
                        Terms & Conditions
                    </p>
                    <p
                        onClick={() => displayRodal(1)}
                    >
                        Privacy Policy
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Footer
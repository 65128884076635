import React from 'react'
import { useNavigate } from 'react-router-dom'
import CardA from '../../img/CardBlackB.svg'
import CardB from '../../img/CardGreenB.svg';
import CardC from '../../img/CardGold.svg';

function Accounts() {

    const navigate = useNavigate()
    return (
        <div className='accounts'>
            <h5 className="accounts__tophead">Account</h5>
            <h2 className="accounts__header">Perfect card <br /> for your needs</h2>
            <p className="accounts__textbox" data-aos="fade-up">
                Our bank's diverse range of card types, including credit, debit, and prepaid cards, enables customers to
                and securely access their funds, make purchases, and manage their finances in a way that best suits their needs.
            </p>

            <div className="accounts__cardcontainer" data-aos="fade-up">
                <div className="accounts__card accounts__card--1">
                    <img src={CardA} alt="" className="accounts__card__img" />
                </div>

                <div className="accounts__card accounts__card--2">
                    <img src={CardB} alt="" className="accounts__card__img" />
                </div>

                <div className="accounts__card accounts__card--3">
                    <img src={CardC} alt="" className="accounts__card__img" />
                </div>
            </div>

            <div className="accounts__btnsection">
                <button
                    className="btn accounts__btn--open"
                    onClick={() => navigate('/signup')}
                >
                    Open Account
                </button>
                <button
                    className="btn accounts__btn--compare"
                    onClick={() => navigate('/compare')}
                >
                    Compare Cards
                </button>
            </div>
        </div>
    )
}

export default Accounts
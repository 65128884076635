import React, { useState, useEffect } from 'react'
import { Header, Footer } from '../../_shared'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import CardA from '../../img/CardBlack.svg';
import CardB from '../../img/CardGreen.svg'
import Services from './Services';
import InstantMoney from './InstantMoney';
import Savings from './Savings';
import Notifications from './Notifications';
import Integrate from './Integrate';
import Accounts from './Accounts';
import Reviews from './Reviews';
import MobileBanking from './MobileBanking';
import Help from './Help';
import Blog from './Blog';
import { useNavigate } from 'react-router-dom';
import {FooterModal, Usage} from '../../_shared';
import ChatHelpBot from '../../chatbot/Chatbot';
import 'react-chatbot-kit/build/main.css'

function Landing() {

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [currentModal, setCurrentModal] = useState(0)
    

    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem('LOGGED_IN', '0')
      }, []);

    return (
        <div className='landing'>
            <Header />
            <FooterModal
                visible={showModal}
                setVisible={setShowModal}
                current={currentModal}
            />

            
            <div className="landing__container">

                <div className="landing__intro" data-aos="fade-up">
                    <h2 className="landing__intro__header">Banking <br />starts here</h2>
                    <p className="landing__intro__text">
                        Welcome to Eindhoven investment Bank, Netherlands's Safest and Goal driven bank to ensure financial freedom.
                    </p>

                    <div className="landing__intro__services">

                        <div className="landing__intro__services__item">
                            <IoMdCheckmarkCircle className='landing__intro__services__item__label' />
                            <p className="landing__intro__services__item__bullet">Instant transactions</p>
                        </div>

                        <div className="landing__intro__services__item">
                            <IoMdCheckmarkCircle className='landing__intro__services__item__label' />
                            <p className="landing__intro__services__item__bullet">Payments Worldwide</p>
                        </div>

                        <div className="landing__intro__services__item">
                            <IoMdCheckmarkCircle className='landing__intro__services__item__label' />
                            <p className="landing__intro__services__item__bullet">Savings Accounts</p>
                        </div>

                        <div className="landing__intro__services__item">
                            <IoMdCheckmarkCircle className='landing__intro__services__item__label' />
                            <p className="landing__intro__services__item__bullet">100% mobile banking</p>
                        </div>
                    </div>

                    <div className="landing__intro__btnbox">
                        <button
                            className='btn landing__intro__open'
                            onClick={() => navigate('signup')}
                        >
                            Open Account
                        </button>
                        <button 
                            className='btn landing__intro__compare'
                            onClick={() => navigate('compare')}
                        >
                            Compare Cards
                        </button>
                    </div>

                </div>


                <div className="landing__card" data-aos="fade-up">
                    <div className="landing__card__container">
                        <img src={CardA} alt="Card for UI" className="landing__card__item landing__card__item--black" />
                        <img src={CardB} alt="Card for UI" className="landing__card__item landing__card__item--green" />
                    </div>
                </div>

            </div>

            <Services />

            <InstantMoney />

            <Savings />

            <Notifications />

            <Integrate />

            <Accounts />

            <Reviews />

            <MobileBanking />

            <Help />

            <Blog />

            <ChatHelpBot/>

            <Footer setCurrentModal={setCurrentModal} setModal={setShowModal}/>

        </div>
    )
}



export default Landing
import React from 'react';
import { IoMdCheckmarkCircle } from 'react-icons/io'
import Phone from '../../img/services_phone2.png'
import AppStore from '../../img/62f4e5e27222c6348dcc8991_badge-app-store.svg';
import Playstore from '../../img/62f4e5e20a419234251b4d8f_badge-google-play.svg';

function MobileBanking() {
    return (
        <div className="mobile">
            <div className="mobile__card">

                <div className="mobile__card__content" data-aos="fade-up">
                    <h2 className="mobile__card__content__header">Simplifying Your <br /> Financial Journey</h2>
                    <div className="mobile__card__content__text">
                        We aim to simplify your financial journey by offering intuitive tools and personalized guidance to help you achieve your goals.
                    </div>
                    <ul className="mobile__card__content__list">
                        <li className="mobile__card__content__list__item">
                            <IoMdCheckmarkCircle className='mobile__card__content__list__item__label' />
                            <span>Personalized financial tools</span>
                        </li>

                        <li className="mobile__card__content__list__item">
                            <IoMdCheckmarkCircle className='mobile__card__content__list__item__label' />
                            <span>Easy account management</span>
                        </li>

                        <li className="mobile__card__content__list__item">
                            <IoMdCheckmarkCircle className='mobile__card__content__list__item__label' />
                            <span>Secure online banking</span>
                        </li>

                        <li className="mobile__card__content__list__item">
                            <IoMdCheckmarkCircle className='mobile__card__content__list__item__label' />
                            <span>24/7 customer support</span>
                        </li>
                    </ul>

                    <div className="mobile__card__content__imgsection">
                        <div className="mobile__card__content__imgbox">
                            <img src={AppStore} alt="Appstore Icon" className="mobile__card__content__img" />
                        </div>

                        <div className="mobile__card__content__imgbox">
                            <img src={Playstore} alt="Playstore Icon" className="mobile__card__content__img" />
                        </div>
                    </div>
                </div>


                <div className="mobile__card__imgsection" data-aos="fade-up">
                    <div className="mobile__card__imgbox">
                        <img src={Phone} alt="Phone for UI" className="mobile__card__img" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileBanking
import React from 'react'
import Screen1 from '../../img/62f6bed19570fefff1dcacc4_app-screen-stats.webp';
import CardBlack from '../../img/CardBlack.svg';
import CardGreen from '../../img/CardGreen.svg';
import CardGold from '../../img/62fa8d2fe6c54d075cde81be_card-gold-rotated.svg';
import Transaction from '../../img/62f7659cfd7b99bb1036e27b_feature-transactions.webp';
import Account from '../../img/62f7659c0a4192866c3a78ec_feature-savings.webp';
import Api from '../../img/62f764b3ea0370fb976d39a5_feature-api.webp';

function OneBank() {
    return (
        <div className='onebank'>
            <h2 className="onebank__header">All in one bank. Really.</h2>
            <p className="onebank__text">
                Discover a wide range of comprehensive financial services,
                all in one place, at our bank, designed to meet your diverse banking needs with convenience, security, and personalized solutions
            </p>
            <div className="onebank__container">

                <div className="onebank__item onebank__item--big">
                    <h6 className="onebank__item__title">Statistics</h6>
                    <p className="onebank__item__text">
                        Empower yourself with financial knowledge through our bank app's informative statistics feature.
                    </p>
                    <div className="onebank__item__imgcontainer onebank__item__imgcontainer--big">
                        <div className="onebank__item__imgbox onebank__item__imgbox--big">
                            <img src={Screen1} alt="Item representation" className="onebank__item__img onebank__item__img--big" />
                        </div>
                    </div>
                </div>

                <div className="onebank__item onebank__item--big">
                    <h6 className="onebank__item__title">Cards</h6>
                    <p className="onebank__item__text">
                        Activate, deactivate, and set spending limits on your cards effortlessly with our app's intuitive cards feature.
                    </p>
                    <div className="onebank__item__imgcontainer onebank__item__imgcontainer--small onebank__item__imgcontainer__card">
                        <div className="onebank__item__imgbox onebank__item__imgbox--small--1">
                            <img src={CardBlack} alt="Item Representation" className="onebank__item__img onebank__item__img--small onebank__item__img--small--1" />
                        </div>

                        <div className="onebank__item__imgbox onebank__item__imgbox--small--2">
                            <img src={CardGreen} alt="Item Representation" className="onebank__item__img onebank__item__img--small onebank__item__img--small--2" />
                        </div>

                        <div className="onebank__item__imgbox onebank__item__imgbox--small--3">
                            <img src={CardGold} alt="Item Representation" className="onebank__item__img onebank__item__img--small onebank__item__img--small--3" />
                        </div>
                    </div>

                </div>

                <div className="onebank__item onebank__item--small">
                    <h6 className="onebank__item__title">Easy Integration</h6>
                    <p className="onebank__item__text">
                        Enjoy seamless integration feature that allows you to easily connect your accounts, cards, and transactions in one place.
                    </p>
                    <div className="onebank__item__imgcontainer">
                        <div className="onebank__item__imgbox onebank__item__imgbox--normal">
                            <img src={Api} alt="Item representation" className="onebank__item__img onebank__item__img--normal" />
                        </div>
                    </div>
                </div>

                <div className="onebank__item onebank__item--small">
                    <h6 className="onebank__item__title">Savings Accounts</h6>
                    <p className="onebank__item__text">
                        Plan for your future with packages for vacations, holidays, and cars, and enjoy the benefits of our reliable account services.
                    </p>
                    <div className="onebank__item__imgcontainer">
                        <div className="onebank__item__imgbox onebank__item__imgbox--normal">
                            <img src={Account} alt="Item representation" className="onebank__item__img onebank__item__img--normal" />
                        </div>
                    </div>
                </div>

                <div className="onebank__item onebank__item--small">
                    <h6 className="onebank__item__title">Instant Transactions</h6>
                    <p className="onebank__item__text">
                        Manage your finances conveniently and securely, and enjoy the convenience of instant money transfers with our bank app
                    </p>
                    <div className="onebank__item__imgcontainer">
                        <div className="onebank__item__imgbox onebank__item__imgbox--normal">
                            <img src={Transaction} alt="Item representation" className="onebank__item__img onebank__item__img--normal" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default OneBank
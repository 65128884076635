import React, { useState } from 'react'
import Rodal from 'rodal'
import { useNavigate } from 'react-router-dom';
// import { sendForgotMail } from './dbFunctions';
import { ClipLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect';
import { FaKey } from 'react-icons/fa';

const fadePage = {
    display: 'none',
    width: '0',
}

function OtpModal(props) {
    
    const navigate = useNavigate()
    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        if (code === props.otpCode) {
            localStorage.setItem('LOGGED_IN', '1')
            setLoading(false)
            navigate('/home')
        } else {
            setLoading(false)
            props.notify("Wrong Code")
        }
    }

    return (
        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.8 : 700}
            height={400}
            closeMaskOnClick={false}
        >

            <div className='otpdd'>
                <h3>ENTER OTP CODE</h3>

                <ClipLoader
                    color="blue"
                    loading={loading}
                    size={80}
                    className='otpdd__loader'
                />

                <form
                    action=""
                    onSubmit={handleSubmit}
                    className='otpdd__container'
                    style={loading ? fadePage : {}}
                    id="codeform"
                >
                    <p>We have sent an email with a verification code to the email connected with this account. Log in and enter it here</p>
                    <div className="otpdd__inputbox">
                        <label htmlFor="code">OTP Code</label>
                        <div className="otpdd__input__wrap">
                            <div className="otpdd__input__icon__box">
                                <FaKey className="otpdd__input__icon" />
                            </div>
                            <input
                                type="text"
                                className='otpdd__input'
                                name="code"
                                id='code'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="otpdd__btnsection">
                        <button className="btn otpdd__btn">Submit</button>
                    </div>
                </form>

            </div>
        </Rodal>
    )
}

export default OtpModal

const Loader = (props) => {
    <div
        className="otpdd__loader"
        style={props.loading ? {} : fadePage}
    >

    </div>
}

import React from 'react'
import { SyncLoader } from 'react-spinners'
import { isMobile } from 'react-device-detect';


const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function Spinner(props) {
    //console.log(isMobile)
    return (
        <div className='spinner'>

            <SyncLoader
                color="#20b2aa"
                loading={props.visible}
                cssOverride={override}
                size={isMobile ? window.innerWidth * 0.15 : 150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />

        </div>
    )
}

export default Spinner
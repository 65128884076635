
const Usage = {
    Terms: {
        header: 'Terms And Conditions',
        text:
            `
    
            These terms and conditions ("Terms") govern the use of our website ("Site") and the services provided by our bank ("Bank"). By accessing or using our Site or services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Site or services.
            
            1. Acceptable Use
            You may use our Site and services only for lawful purposes and in compliance with these Terms. You agree not to use our Site or services:
    
            - In any way that violates any applicable federal, state, local, or international law or regulation.
            - To engage in any activity that could harm or disrupt our Site or services, or the servers or networks connected to our Site or services.
            - To impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.
            - To attempt to gain unauthorized access to any portion of our Site or services, or any other systems or networks connected to our Site or services.
            - To interfere with or circumvent the security features of our Site or services, or any other systems or networks connected to our Site or services.
            - To engage in any activity that could damage, disable, overburden, or impair our Site or services, or any other systems or networks connected to our Site or services.
    
            2. Privacy Policy
            Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and disclose information about you when you use our Site and services.
    
            3. Accounts
            To access certain features of our Site and services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process, and to update such information as necessary to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account or password.
    
            4. Fees and Charges
            We may charge fees for certain services provided through our Site or services. You agree to pay all fees and charges associated with your use of our Site or services. All fees and charges are non-refundable.
    
            5. Intellectual Property
            The content and materials on our Site, including text, graphics, logos, images, and software, are protected by copyright and other intellectual property laws. You agree not to copy, modify, reproduce, distribute, display, or transmit any content or materials on our Site without our prior written consent.
    
            6. Disclaimer of Warranties
            Our Site and services are provided "as is" and "as available," without warranty of any kind, either express or implied, including without limitation any warranty for information, services, uninterrupted access, or products provided through or in connection with our Site or services, including without limitation the software licensed to you and the results obtained through the Site or services. Specifically, we disclaim any and all warranties, including without limitation: 1) any warranties concerning the availability, accuracy, usefulness, or content of information, products, or services; and 2) any warranties of title, warranty of non-infringement, warranties of merchantability or fitness for a particular purpose.
    
            7. Limitation of Liability
            In no event shall we be liable to you or any third party for any damages (including without limitation direct, indirect, incidental, special, consequential, or punitive damages, and damages for loss of profits, goodwill, use, data, or other intangible losses) arising out of or relating to: (i) your use or inability to use our Site or services; (ii) any third-party conduct or content on our Site or services; or (iii) any unauthorized access, use, or alteration of your transmissions
    
        `
    },

    Privacy: {

        header: "Privacy Policies",
        text: `
        
            This privacy policy ("Policy") explains how we collect, use, and disclose information about you when you use our website ("Site") and the services provided by our bank ("Bank"). By accessing or using our Site or services, you agree to be bound by this Policy. If you do not agree to this Policy, you may not use our Site or services.
        
            1. Information We Collect
            We may collect personal information that can identify you, such as your name, address, email address, telephone number, and other information that you provide to us when you use our Site or services. We may also collect non-personal information, such as your IP address, browser type, domain names, access times, and referring website addresses.
        
            2. How We Use Your Information
            We may use the information we collect from you to:
        
            - Provide and improve our Site and services.
            - Respond to your inquiries and requests.
            - Customize the content and advertising you see on our Site.
            - Send you promotional communications about our products and services.
            - Enforce our Terms and other policies.
        
            3. Disclosure of Your Information
            We may disclose your personal information to:
        
            - Our affiliates, who may use your information to provide services to you on our behalf.
            - Our service providers, who may use your information to provide services to us, such as website hosting, data analysis, payment processing, and other services.
            - Third-party advertisers, who may use your information to show you ads that are relevant to your interests.
            - Law enforcement or other government officials, in response to a subpoena, court order, or other legal process.
        
            4. Cookies and Other Tracking Technologies
            We may use cookies, web beacons, and other tracking technologies to collect information about your use of our Site and services. Cookies are small data files that are stored on your device when you visit our Site. Web beacons are small graphic images that allow us to track your actions on our Site. We may use cookies and web beacons to personalize your experience on our Site, to improve our Site and services, and to analyze how our Site and services are used.
        
            5. Security
            We take reasonable measures to protect the personal information we collect from you against unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your personal information.
        
            6. Children's Privacy
            Our Site and services are not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will take reasonable steps to delete the information as soon as possible.
        
            7. Changes to This Policy
            We may update this Policy from time to time. If we make material changes to this Policy, we will notify you by posting a notice on our Site or by sending you an email.
        
            8. Contact Us
            If you have any questions about this Policy or our privacy practices, please contact us at [insert contact information].
            `
    },
    Copyright: {
        header: 'Copyright',
        text: `

            This website ("Site") and its contents are copyright © 2002 by [insert bank name], and all rights are reserved. The materials on this Site are protected by United States copyright law and international treaties.

            1. Ownership of Materials
            All materials on this Site, including but not limited to text, graphics, images, logos, icons, audio clips, software, and code (collectively, "Materials"), are the property of [insert bank name] or its content suppliers and are protected by United States copyright law and international treaties.

            2. Use of Materials
            You may view, download, and print Materials from this Site for your personal, noncommercial use only, provided that you retain all copyright and other proprietary notices contained in the Materials. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any Materials obtained from this Site, without the express written consent of [insert bank name]. Any unauthorized use of the Materials on this Site may violate copyright laws, trademark laws, privacy laws, and other laws and regulations.

            3. Trademarks
            The trademarks, service marks, and logos (collectively, "Trademarks") used and displayed on this Site are registered and unregistered trademarks of [insert bank name] and its affiliates, or their respective owners. Nothing on this Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Site, without the express written consent of [insert bank name] or the relevant owner of the Trademark. Misuse of any Trademark is strictly prohibited.

            4. Digital Millennium Copyright Act
            [Insert bank name] respects the intellectual property rights of others and expects its users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide [insert bank name] with the following information, in writing:

            - A description of the copyrighted work that you claim has been infringed;
            - A description of where the allegedly infringing material is located on the Site;
            - Your address, telephone number, and email address;
            - A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
            - A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.

            Please send your written notice to [insert contact information].

            5. Termination
            [Insert bank name] may terminate your access to this Site at any time, without notice, for any reason whatsoever.

            6. General
            This Agreement shall be governed by and construed in accordance with the laws of the State of [insert state], without regard to its conflicts of law provisions. Any dispute arising out of or relating to this Agreement or your use of this Site shall be brought exclusively in the state or federal courts located in [insert city and state], and you hereby consent to the personal jurisdiction of such courts. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. The failure of [insert bank name] to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by [insert bank name] in writing.

            7. Contact Us
            If you have any questions about this Copyright Notice or our copyright practices, please contact us at [insert contact information].
        `
    }


}


export default Usage
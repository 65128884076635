import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import User from '../../img/user.png';
import { IoLogOut } from 'react-icons/io5'
import Logo from '../../img/logo.svg';
import { connect } from 'react-redux';

function Header(props) {

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {

    renderCurrentMenu()


  }, [props.page])


  function renderCurrentMenu() {
    switch (props.page) {
      case 0:
        return 'Dashboard'

      case 1:
        return 'Transfer Money'

      case 2:
        return 'Support'

      default:
        break;
    }

  }

  return (
    <div className='home__header'>

      <div className="home__header__logobox">
        <img src={Logo} alt="" className="home__header__logo" />
      </div>

      <div className="home__header__currentpage">
        {renderCurrentMenu()}
      </div>

      <div className="home__header__user">
        <p className="home__header__user__welcome">{`Hello ${props.userData.data.FullName}`}</p>
        <img src={User} alt="User avatar" className="home__header__user__avatar" />
        <div
          className="home__header__user__logout"
          onClick={() => navigate('/')}
        >
          <IoLogOut className="home__header__user__logout__icon" />
          <p>Logout</p>
        </div>
      </div>

      <div
        className="home__header__menu"
        onClick={() => setShowDropdown(!showDropdown)}
      >

        <div className="header__menu__line">
          <div className="header__menu__line--1" />
          <div className="header__menu__line--2" />
          <div className="header__menu__line--3" />
        </div>

        <div
          className="header__menu__dropdown"
          style={showDropdown ? { display: 'block', width: '100vw' } : {}}
        >
          <ul>
            <li onClick={() => props.setPage(0)}>
              Dashboard
            </li>
            <li onClick={() => props.setPage(1)}>
              Transfer
            </li>
            <li onClick={() => props.setPage(2)}
            >
              Support
            </li>
            <li
              onClick={() => {
                localStorage.setItem('LOGGED_IN', '0')
                navigate('/')
              }
              }
            >
              Logout
            </li>

          </ul>
        </div>

      </div>

    </div>
  )
}

const mapStateToProps = (state) => ({
  userData: state.current_user,
})

export default connect(mapStateToProps)(Header)
import React, { useState, useEffect } from 'react'
import Header from './Header'
import Dashboard from './Dashboard'
import Sidebar from './Sidebar'
import QuickTransfer from './QuickTransfer'
import { Help } from '../Landing'
import { useNavigate } from 'react-router-dom'


function Home(props) {

  const [currentPage, setCurrentPage] = useState(0);
  // const user = parseInt(localStorage.getItem('LOGGED_IN'))
  // const navigate = useNavigate()
  

  useEffect(() => {

    // user ? renderContent() : navigate('/login', {replace: true})
    
    renderContent()
  }, [currentPage])


  function renderContent() {
    switch (currentPage) {
      case 0:
        return (
          <Dashboard />
        );

      case 1:
        return (
          <QuickTransfer />
        );

      case 2:
        return (
          <Help />
        );

      default:
        break;
    }
  }

  return (
    <div className='home'>
      <div className="home__maincontainer">
        <Sidebar page={currentPage} setPage={setCurrentPage} />
        <div className="home__contentcontainer">
          <Header page={currentPage} setPage={setCurrentPage} />
          {renderContent()}
        </div>
      </div>

    </div>
  )
}



export default Home
import React from 'react'
import { AiFillStar } from 'react-icons/ai'

const bankReviews = [
    {
        title: 'Excellent Service',
        message: 'I have been a customer of this bank for years and I have always been impressed by their exceptional service. The staff is knowledgeable and friendly, and they always go the extra mile to ensure my satisfaction.',
        name: 'John Smith',
        profession: 'Engineer'
    },
    {
        title: 'Great Rates and Features',
        message: 'I switched to this bank for their great rates and features, and I have not been disappointed. Their online banking is easy to use and their mobile app is very convenient. I would definitely recommend this bank to anyone looking for a great banking experience.',
        name: 'Samantha Jones',
        profession: 'Marketing Manager'
    },
    {
        title: 'Unprofessional Staff',
        message: 'I had a terrible experience at this bank. The staff was unprofessional and rude, and they made me feel like I was inconveniencing them. I will never do business with this bank again.',
        name: 'David Lee',
        profession: 'Sales Representative'
    },
    {
        title: 'Fast and Convenient',
        message: 'I needed to open a new account quickly and this bank was able to accommodate me on short notice. The staff was very helpful and the process was fast and convenient.',
        name: 'Emily Chen',
        profession: 'Student'
    },
    {
        title: 'Easy to Get Started',
        message: 'I was a bit nervous about opening a new bank account, but the staff at this bank made the process so easy. They answered all of my questions and helped me choose the account that was best for me.',
        name: 'Liam Johnson',
        profession: 'Graphic Designer'
    },
    {
        title: 'Great Customer Service',
        message: 'Whenever I have a question or need help with something, the customer service at this bank is always top-notch. They are knowledgeable, patient, and always willing to go the extra mile.',
        name: 'Avery Rodriguez',
        profession: 'Writer'
    },
    {
        title: 'Highly Recommend',
        message: 'I have been banking with this institution for years and I would highly recommend them to anyone looking for a reliable and trustworthy bank. They offer great rates, convenient services, and excellent customer support.',
        name: 'Emma Davis',
        profession: 'Business Analyst'
    },
    {
        title: 'Amazing Experience',
        message: 'I had high hopes for this bank and i can boldly say I was astonished by the communication and transparency of services. Notifications, alerts and messages work like magic.The best Bank. Thank You Banquee',
        name: 'Michael Brown',
        profession: 'Project Manager'
    }
];

function Reviews() {

    return (
        <div className='reviews'>
            <h5 className="reviews__tophead">Testimonials</h5>
            <div className="reviews__headerBox" data-aos="fade-up">
                <h2 className="reviews__header">People all over the world use banquee.</h2>
                <div className="reviews__overallrate">
                    <div className="reviews__overallrate__label">
                        <AiFillStar className='reviews__overallrate__star' />
                    </div>
                    <p className="reviews__overallrate__text">Rated <span>4.8/5</span> from over <span>1000</span> users</p>
                </div>
            </div>

            <div className="reviews__container">
                {
                    bankReviews.map((review, index) => (
                        <ReviewItem data={review} />
                    ))
                }

            </div>
        </div>
    )
}


const ReviewItem = (props) => {
    //console.log('hello')
    return (
        <div className="reviews__item" data-aos="fade-up">
            <div className="reviews__item__starbox">
                <AiFillStar className='reviews__item__star' />
                <AiFillStar className='reviews__item__star' />
                <AiFillStar className='reviews__item__star' />
                <AiFillStar className='reviews__item__star' />
                <AiFillStar className='reviews__item__star' />
            </div>

            <h3 className="reviews__item__header">{props.data.title}</h3>
            <p className="reviews__item__text">{props.data.message}</p>
            <div className="reviews__item__reviewer">
                <p className="reviews__item__reviewer__name">{props.data.name}</p>
                <p className="reviews__item__reviewer__job">{props.data.profession}</p>
            </div>
        </div>

    )
}

export default Reviews
import React, { Component } from 'react';
// import emailjs from 'emailjs-com';

import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

import { connect } from 'react-redux';
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import DB from '../../firebase';
import { Spinner, FrozenModal } from '../../_shared';
import { setCurrentUser } from '../../redux/actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';



const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
class QuickTransfer extends Component {

    state = {
        displayBill: false,
        loading: false,
        country: '',
        modalVisible: false,
    }

    

    render() {

        return (
            <div>
                <Spinner visible={this.state.loading} />
                <FrozenModal visible={this.state.modalVisible} setVisible={() => this.setState({ modalVisible: false })}/>

                <section className="section-transfer" style={this.state.loading ? { display: 'none', width: '0' } : {}}>
                    <div className="transfer__row">
                        <div className="transfer__book">
                            <div className="transfer__book__form">
                                <form action="#" className="transfer__form" onSubmit={(e) => { this.handleSubmit(e) }}>


                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Bank name" id="bankname" required />
                                        <label for="bankname" className="transfer__form__label">Bank Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        {
                                            /*
                                            <input type="text" className="transfer__form__input" placeholder="Country Of Location" id="country" required />
                                            */
                                        }

                                        <CountryDropdown
                                            value={this.state.country}
                                            onChange={(country) => this.setState({ country })}
                                            className="transfer__form__input"
                                        />



                                        <label for="country" className="transfer__form__label">Country</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Name" id="accountname" required />
                                        <label for="accountname" className="transfer__form__label">Account Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Number" id="accountnumber" required />
                                        <label for="accountnumber" className="transfer__form__label">Account Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Routing Number" id="routingnumber" required />
                                        <label for="routingnumber" className="transfer__form__label">Routing Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="number" className="transfer__form__input" placeholder="Amount" id="amount" required />
                                        <label for="amount" className="transfer__form__label">Amount in (&euro;)</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="email" className="transfer__form__input" placeholder="Your Email" id="email" required />
                                        <label for="email" className="transfer__form__label">Your Email</label>
                                    </div>

                                    <div className="transfer__form__btnbox">
                                        <button class="btn transfer__form__btn" type='submit'>Transfer Money &rarr;</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section >
                <ToastContainer />
            </div>

        );
    }


    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true })

        let bankName = event.target[0].value;
        let country = event.target[1].value;
        let accountName = event.target[2].value;
        let accountNumber = event.target[3].value;
        let routingNumber = event.target[4].value;
        let amount = event.target[5].value;
        let email = event.target[6].value;



        //console.log(email);
        //console.log(country);
        //console.log(bankName);
        //console.log(accountName);
        //console.log(accountNumber);
        //console.log(amount);
        //console.log(routingNumber);
        console.log(this.props.userData.data)

        if (this.props.userData.data.Active) {

            if (amount > 20000) {
                this.setState({ loading: false })
                alert('Maximum transaction amount has been exceeded')
            } else {
    
                if (this.props.userData.data.Balance - 2000 < amount) {
                    this.setState({ loading: false })
                    alert('Please enter a smaller amount')
                } else {
                    const templateParams = {
                        email: email,
                        country: country,
                        bank_name: bankName,
                        account_name: accountName,
                        account_number: accountNumber,
                        amount: amount,
                        routing_number: routingNumber,
                    };
    
                    this.insertData(templateParams)
    
                }
    
    
            }

        } else {
            this.setState({ 
                loading: false,
                modalVisible: true 
            })
        }


    }

    insertData = async (templateParams) => {

        const updatedRef = doc(getFirestore(), "users", this.props.userData.id);

        let currentTransactions = JSON.parse(this.props.userData.data.Transaction)
        let updatedTransactions = [...currentTransactions, {
            title: "Pending Transaction",
            date: new Date().toJSON().slice(0, 10),
            type: 0,
            amt: templateParams.amount,
            balance: parseFloat(this.props.userData.data.Balance) - parseFloat(templateParams.amount),
            pendingInfo: JSON.stringify(templateParams),

            ID: this.generateString(14),
            src: `${templateParams.bank_name} ${templateParams.account_number}`,
            status: 'Pending',
            desc: 'No Description Provided'
        }]

        await updateDoc(updatedRef, {
            Transaction: JSON.stringify(updatedTransactions),
            Balance: parseFloat(this.props.userData.data.Balance) - parseFloat(templateParams.amount),
        });

        this.props.setCurrentUser({ id: this.props.userData.id, data: { ...this.props.userData.data, Transaction: JSON.stringify(updatedTransactions), Balance: parseInt(this.props.userData.data.Balance) - parseInt(templateParams.amount) } })

        const inputArray = document.getElementsByClassName("transfer__form__input");
        //console.log(inputArray)
        inputArray[0].value = ""
        inputArray[1].value = ""
        inputArray[2].value = ""
        inputArray[3].value = ""
        inputArray[4].value = ""
        inputArray[5].value = ""
        inputArray[6].value = ""

        this.setState({ loading: false })

        this.notify()
    }

    notify = (text) => {

        toast.error("Transaction is pending review. ", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
        });

    };




    generateString(length) {
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }



}

const BillRodal = (
    <Rodal visible={false} onClose={() => console.log(' ')}>
        <div style={{ fontSize: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%' }}>This account is not yet setup for online transfer. Kindly contact management</div>
    </Rodal>
)

const mapStateToProps = (state) => ({
    userData: state.current_user,
})

export default connect(mapStateToProps, { setCurrentUser })(QuickTransfer);
import React, { useState } from 'react'
import CardGreen from '../../img/CardGreenB.svg';
import { BsPersonFill } from 'react-icons/bs'
import { FaLock } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../../_shared'
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import DB from '../../firebase';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from "@emailjs/browser";
import OtpModal from './Otp';

function Login(props) {

    const navigate = useNavigate()
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [showOtp, setShowOtp] = useState(false)
    const [Otp, setOtp] = useState('')
    let [loading, setLoading] = useState(false);

    const signIn = async (e) => {

        e.preventDefault()
        // console.log("here")
        let user = null;
        setLoading(true)
        //console.log(setUser)
        const q = query(collection(getFirestore(), "users"), where("Username", "==", username), where("Password", "==", password));
        const querySnapshot = await getDocs(q);
        //console.log(querySnapshot)
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            user = doc;
        });

        if (user === null) {
            setLoading(false)
            notify("Incorrect Login Credentials")
        } else {
            // setUser({ id: user.id, data: user.data() })
            props.setCurrentUser({ id: user.id, data: user.data() })
            //localStorage.setItem('LOGGED_IN', '1')
            // setLoading(false)

            const myForm = document.getElementById("otpform")
            // console.log(user.data().Email)
            sendEmail(user.data().Email, myForm, setLoading, setShowOtp, setOtp)

            // navigate('/home')
        }
    }

    return (
        <div className='login'>
            <Spinner visible={loading} />
            <OtpModal
                showModal={showOtp}
                hideModal={() => setShowOtp(false)}
                otpCode={Otp}
                notify={notify}
            />
            <div className="login__content" style={loading ? { display: 'none', width: '0' } : {}}>
                <div className="login__imgbox">
                    <img src={CardGreen} alt="Card Background" className="login__img" style={{ fill: 'red' }} />
                </div>

                <div className="login__formbox">

                    <form
                        className="login__form"
                        id="otpform"
                        action=""
                        onSubmit={signIn}
                    >
                        <div className="login__inputbox">
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="login__input"
                                placeholder='Username'
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <div className="login__input__icon__box">
                                <BsPersonFill className='login__input__icon' />
                            </div>
                        </div>

                        <div className="login__inputbox">
                            <input
                                type="password"
                                className="login__input"
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="login__input__icon__box">
                                <FaLock className='login__input__icon' />
                            </div>
                        </div>

                        <div className="login__btnsection">
                            <button
                                className="btn login__btn login__btn--log"
                            //onClick={() => signIn(username, password, setLoading, navigate, props.setCurrentUser)}
                            >Login</button>
                            <button
                                className="btn login__btn login__btn--forgot"
                                onClick={() => navigate('/support')}
                            >
                                Forgot Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )


}


const notify = (message) => {

    toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
    });

};

function generateOTP() {
    // Generate a 6-digit random number
    const otp = Math.floor(100000 + Math.random() * 900000);
    return otp.toString();
}

const sendEmail = (email, formRef, setLoading, setShowOtp, setOtp) => {
    
    const OtpCode = generateOTP()
    setOtp(OtpCode)
    
    const linkInput = document.createElement('input');
    linkInput.setAttribute('type', 'hidden');
    linkInput.setAttribute('name', 'email');
    linkInput.setAttribute('value', email);

    const linkInput2 = document.createElement('input');
    linkInput2.setAttribute('type', 'hidden');
    linkInput2.setAttribute('name', 'code');
    linkInput2.setAttribute('value', OtpCode);

    formRef.appendChild(linkInput)
    formRef.appendChild(linkInput2)

    emailjs
        .sendForm(
            "service_dxwelcu", // Replace with your service ID
            "template_vk9myzf", // Replace with your template ID
            formRef,
            {
                publicKey: "ObusMcYAtBDJhfryo" // Replace with your public key
            })
        .then((response) => {
            setLoading(false)
            setShowOtp(true)
            //console.log("Email sent successfully:", response.status);
        })
        .catch((error) => {
            setLoading(false)

            console.error("Failed to send email:", error);
        });
};



export default connect(null, { setCurrentUser })(Login);
import React from 'react'
import { AiFillCreditCard } from 'react-icons/ai'
import { IoMdAdd } from 'react-icons/io'
import {RiBankFill} from 'react-icons/ri'
import {BsPersonHearts} from 'react-icons/bs'
import { FAQData } from '../../_shared'

function FAQ() {
    return (
        <div className='sptfaq'>
            <div className="sptfaq__key">
                <h4 className="sptfaq__key__header">Categories</h4>
                <ul>
                    <li>Cards</li>
                    <li>Account</li>
                    <li>Personal Details</li>
                </ul>
            </div>
            <div className="sptfaq__faq">
                <FAQItem
                    data={FAQData.filter((item) => item.category === "cards")}
                    category="Cards"
                >
                    <AiFillCreditCard className='sptfaq__faq__head__img' />
                </FAQItem>

                <FAQItem
                    data={FAQData.filter((item) => item.category === "account")}
                    category="Account"
                >
                    <RiBankFill className='sptfaq__faq__head__img' />
                </FAQItem>

                <FAQItem
                    data={FAQData.filter((item) => item.category === "personal details")}
                    category="Personal Details"
                >
                    <BsPersonHearts className='sptfaq__faq__head__img' />
                </FAQItem>

            </div>

        </div>
    )
}

const FAQItem = (props) => {
    return (
        <div className="sptfaq__faq__item">
            <div className="sptfaq__faq__head">
                <div className="sptfaq__faq__head__imgbox">
                    {props.children}
                </div>
                <h4 className="sptfaq__faq__head__title">{props.category}</h4>
            </div>

            <ul className="sptfaq__faq__section">
                {
                    props.data.map((data) => (
                        <li>
                            <div className="sptfaq__faq__section__head">
                                <p>{data.question}</p>
                                <IoMdAdd className='sptfaq__faq__section__head__add' />
                            </div>
                            <div className="sptfaq__faq__section__text">
                                {data.answer}
                            </div>
                        </li>

                    ))
                }


            </ul>
        </div>
    )
}

export default FAQ
import React from 'react'
import { HiPhone } from 'react-icons/hi'
import { MdEmail, MdLocationPin } from 'react-icons/md'
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai'

const faqs = [
  {
    question: "What services does the bank offer?",
    answer: "Our bank offers a range of services, including personal and business banking, loans, mortgages, credit cards, and investment options. Please visit our website or speak with one of our representatives for more information."
  },
  {
    question: "What are the bank's hours of operation?",
    answer: "Our bank is open from 9:00am to 5:00pm, Monday through Friday, and from 9:00am to 12:00pm on Saturdays. We are closed on Sundays and federal holidays."
  },
  {
    question: "How can I open a bank account?",
    answer: "You can open a bank account by visiting one of our branches in person or by applying online through our website. You will need to provide some personal and financial information and identification documents to complete the process."
  },
  {
    question: "What is the minimum balance requirement for a bank account?",
    answer: "The minimum balance requirement varies depending on the type of account you open. Please visit our website or speak with one of our representatives for more information."
  },
  {
    question: "Does the bank offer mobile banking?",
    answer: "Yes, we offer mobile banking through our app, which is available for download on iOS and Android devices. You can use the app to check your account balance, transfer funds, pay bills, and more."
  },
  {
    question: "What are the fees for using the bank's ATM?",
    answer: "We do not charge any fees for using our own ATMs, but there may be fees from other banks for using their ATMs. Please visit our website or speak with one of our representatives for more information."
  },
  {
    question: "What security measures does the bank have in place to protect my account?",
    answer: "We have multiple security measures in place, including encryption, fraud monitoring, and two-factor authentication. We also recommend that you use strong passwords and avoid sharing your account information with anyone."
  },
  {
    question: "What happens if I lose my debit card or it gets stolen?",
    answer: "If you lose your debit card or it gets stolen, you should contact us immediately to report it. We will block the card and issue you a new one. You may also be liable for any unauthorized transactions made before you report the loss or theft, so it's important to act quickly."
  },
  {
    question: "How can I apply for a loan?",
    answer: "You can apply for a loan by visiting one of our branches in person or by applying online through our website. You will need to provide some personal and financial information and documentation to complete the process. Please visit our website or speak with one of our representatives for more information."
  },
  {
    question: "What is the interest rate for a savings account?",
    answer: "The interest rate for a savings account varies depending on the account type and balance. Please visit our website or speak with one of our representatives for more information."
  }
];


function Help() {
  return (
    <div className='help'>
      <div className="help__content">
        <h2 className="help__content__header">Need help?</h2>

        <div className="help__content__item" data-aos="fade-up">
          <div className="help__content__item__label">
            <HiPhone className='help__content__item__img' />
          </div>

          <div className="help__content__item__textbox">
            <p className="help__content__item__main">0031 176 123 438</p>
            <p className="help__content__item__sub">Support Hotline</p>
          </div>
        </div>

        <div className="help__content__item" data-aos="fade-up">
          <div className="help__content__item__label">
            <MdEmail className='help__content__item__img' />
          </div>

          <div className="help__content__item__textbox">
            <p className="help__content__item__main">support@eindhoveninvestment.com</p>
            <p className="help__content__item__sub">Support Email</p>
          </div>
        </div>

        <div className="help__content__item" data-aos="fade-up">
          <div className="help__content__item__label">
            <MdLocationPin className='help__content__item__img' />
          </div>

          <div className="help__content__item__textbox">
            <p className="help__content__item__main">28 Weteringschans, Amsterdam</p>
            <p className="help__content__item__sub">Address</p>
          </div>
        </div>

        <div className="help__content__btnsection">
          <button className="btn help__content__btn">Support &rarr;</button>
        </div>
      </div>

      <div className="help__faq">
        <div className="help__faq__container">
          <FAQItem data={faqs[0]} />
          <FAQItem data={faqs[1]} />
          <FAQItem data={faqs[2]} />
          <FAQItem data={faqs[7]} />
          <FAQItem data={faqs[4]} />
        </div>
      </div>

    </div>
  )
}

const FAQItem = (props) => {

  return (
    <div className="help__faq__item" data-aos="fade-up">
      <div className="help__faq__item__titlebox">
        <p className="help__faq__item__title">{props.data.question}</p>
        <AiOutlinePlus className="help__faq__item__icon" />
      </div>
      <div className="help__faq__item__content">
        {props.data.answer}
      </div>
    </div>
  )
}

export default Help
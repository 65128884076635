import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { connect } from 'react-redux';

const transactionData = [
  // Type 0 --> Withdrawal,  1---> Deposit
  {
    title: "Bank Deposit",
    date: "22/05/22",
    type: 1,
    amt: 1400000.00,
    balance: 1400000.00,

  },

  {
    title: "Bank Deposit",
    date: "09/09/22",
    type: 1,
    amt: 200786.09,
    balance: 1600786.09,
  },

  {
    title: "ATM Withdrawal",
    date: "15/01/23",
    type: 0,
    amt: 200000.00,
    balance: 1400786.09,

  },

  {
    title: "ATM Withdrawal",
    date: "30/03/23",
    type: 0,
    amt: 200000.00,
    balance: 1200786.09,
  },
]



export const BalanceChart = (props) => {

  //console.log(JSON.stringify(transactionData))

  return (
    <ResponsiveContainer width="90%" height="90%">
      <AreaChart
        width={500}
        height={400}
        data={JSON.parse(props.data.Transaction)}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="balance" stackId="1" stroke="#8884d8" fill="#8884d8" />

      </AreaChart>
    </ResponsiveContainer>
  )
}

const barData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];


export const IncomeChart = (props) => {
  return (
    <ResponsiveContainer width="90%" height="90%">
      <BarChart width={150} height={40} data={barData}>
        <Bar dataKey="uv" fill="#2998ff" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export const ExpenseChart = (props) => {
  return (
    <ResponsiveContainer width="90%" height="90%">
      <BarChart width={150} height={40} data={barData}>
        <Bar dataKey="uv" fill="#ff7730" />
      </BarChart>
    </ResponsiveContainer>
  )
}




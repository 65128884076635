import React from 'react'
import Rodal from 'rodal';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;

function FrozenModal(props) {
  return (
    <div className='frozenmodal__container'>
        <Rodal
                visible={props.visible}
                onClose={() => props.setVisible(false)}
                width={deviceWidth > 425 ?  deviceWidth * 0.6 : deviceWidth * 0.95}
                height={520}
                closeMaskOnClick={false}

            >

                <div className='frozenmodal'>
                    <h2>
                        Further Action Required
                    </h2>
                    <p>
                        Hello Customer, we wanted to bring to your attention that your account with us has exceeded the normal inactivity threshold of 5 years and now has been declared dormant. 
                        We value you as our esteemed customer and want to ensure that your banking experience remains smooth and secure.
                        To reactivate your account and regain access to your funds and services, 
                        we kindly request you to visit our nearest branch or contact our customer support team at support@eindhoveninvestment.com. 
                        Our dedicated team will be glad to assist you with the necessary procedure to reactivate your account.
                    </p>

                    <button
                        className="myrodal__btn btn"
                        onClick={() => props.setVisible(false)}
                    >Close</button>
                </div>
            </Rodal>
    </div>
  )
}

export default FrozenModal
import React from 'react'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import BankLogo from '../../img/bing.png';

function Notifications() {
    return (
        <div className='notifications'>

            <div className="notifications__content" data-aos="fade-up">
                <div className="notifications__tophead">Notifications</div>
                <h2 className="notifications__header">Stay Notified</h2>
                <p className="notifications__textbox">
                    Our notification system keeps you informed about your account activity in real-time,
                    with customizable alerts and user-friendly options that ensure you stay up-to-date and in control of your finances.
                </p>
                <ul className="notifications__list">
                    <li className="notifications__list__item">
                        <IoMdCheckmarkCircle className='notifications__list__item__label' />
                        <span>Receive real-time notifications.</span>
                    </li>
                    <li className="notifications__list__item">
                        <IoMdCheckmarkCircle className='notifications__list__item__label' />
                        <span>Customize your alerts.</span>
                    </li>
                    <li className="notifications__list__item">
                        <IoMdCheckmarkCircle className='notifications__list__item__label' />
                        <span>Stay informed and in control always.</span>
                    </li>
                </ul>
                <div className="notifications__seeall">
                    <button className="btn notifications__seeall__btn">All Features</button>
                </div>

            </div>

            <div className="notifications__receipt" >
                <div className="notifications__receipt__container">
                    <SampleItem
                        message="You payment of 49€ has been processed!"
                    />

                    <SampleItem
                        message="You payment was declined!"
                    />

                    <SampleItem
                        message="Please verify your payment of 99€!"
                    />

                    <SampleItem
                        message="New account statistics are available!"
                    />

                    <SampleItem
                        message="Have a demo of our new and improved features"
                    />
                </div>
            </div>
        </div>
    )
}

const SampleItem = (props) => {
    
    return (
        <div className="notifications__receipt__item" data-aos="fade-up">
            <div className="notifications__receipt__item__labeltextbox">
                <div className="notifications__receipt__item__label">
                    <img src={BankLogo} alt="Bank Logo" className="notifications__receipt__item__img" />
                </div>
                <div className="notifications__receipt__item__textbox">
                    <p className="notifications__receipt__item__textbox__main">Banquee</p>
                    <p className="notifications__receipt__item__textbox__sub">{props.message}</p>
                </div>
            </div>

        </div>
    )
}

export default Notifications
import React, { useState } from 'react'
import Logo from '../img/logoimg.png';
import { useNavigate } from 'react-router-dom'

function Header(props) {

    const navigate = useNavigate();

    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <div className='header'>
            <div className='header__container'>
                <div 
                    className="header__logobox"
                    onClick={() => navigate('/')}
                >
                    <img src={Logo} alt="Logo of the company" className="header__logo" />
                </div>

                <ul className="header__droplinksbox">

                    <li
                        className="header__droplinks"
                        onClick={() => navigate('/compare')}
                    >
                        Compare
                    </li>

                    <li
                        className="header__droplinks"
                        onClick={() => navigate('/features')}
                    >
                        Features
                    </li>
                    <li
                        className="header__droplinks"
                        onClick={() => navigate('/support')}
                    >
                        Support
                    </li>
                    <li
                        className="header__droplinks"
                        onClick={() => navigate('/blog')}
                    >
                        Blog
                    </li>
                </ul>

                <div className="header__account">
                    <button
                        className='btn header__btn--login'
                        onClick={() => navigate('/login')}
                    >
                        Login
                    </button>
                    <button
                        className='btn header__btn--open'
                        onClick={() => navigate('/signup')}
                    >
                        Open Account
                    </button>
                </div>


                <div 
                    className="header__menu"
                    onClick={() => setShowDropdown(!showDropdown)}
                >

                    <div className="header__menu__line">
                        <div className="header__menu__line--1" />
                        <div className="header__menu__line--2" />
                        <div className="header__menu__line--3" />
                    </div>

                    <div 
                        className="header__menu__dropdown"
                        style={showDropdown ? {display: 'block', width: '100vw'} : {}}
                    >
                        <ul>
                            <li onClick={() => navigate('/compare')}>
                                Compare
                            </li>
                            <li onClick={() => navigate('/features')}>
                                Features
                            </li>
                            <li onClick={() => navigate('/support')}
                            >
                                Support
                            </li>
                            <li onClick={() => navigate('/blog')}
                            >
                                Blog
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    )
}


export default Header
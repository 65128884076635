import { createChatBotMessage } from "react-chatbot-kit";

class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {
      if (message.includes('hello') || message.includes('hi')) {
        this.actionProvider.greet()
      } else {
        this.actionProvider.respond()
      }
    }
  }
  
  export default MessageParser;
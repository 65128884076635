
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5zFoPXFzw56c0q1WmPXh3JVYeJ4KUD08",
  authDomain: "eindhoven-af744.firebaseapp.com",
  projectId: "eindhoven-af744",
  storageBucket: "eindhoven-af744.appspot.com",
  messagingSenderId: "877834709916",
  appId: "1:877834709916:web:a07bd30b5ed0d6d35a3659"
};

// Initialize Firebase
const DB = initializeApp(firebaseConfig);
//console.log(DB)
export default DB;
import initialState from './initialState';
import Types from './Types';
import _ from 'lodash';


const AppReducer = (state = initialState, action) => {
  switch (action.type) {


    case Types.SET_CURRENT_USER: {
      //console.log(action.payload)
      return { ...state, current_user: action.payload, }
    }

    case Types.SET_CHATBOT: {
      //console.log(action.type)
      return { ...state, showChatbot : !state.showChatbot, }
    }
   


    
    

    default:
        //console.log('default hit')
      return state;
  }
};

export default AppReducer;

/*
// PUSH THE INITIAL CONTROL SETTINGS TO PREVENT ERROR AND POP WHEN SUBMITTING QUESTIONS TO SERVER
      controlArray.push(['Quiz', 20, 20])
*/
import Img1 from '../img/blake-wisz--JJg90OAnWI-unsplash.jpg';
import Img2 from '../img/jason-goodman-bzqU01v-G54-unsplash.jpg';
import Img3 from '../img/pexels-pixabay-34761.jpg';

const BlogData = 
[
    {
      title: "How I Paid Off My Car Loan in Record Time",
      blogpost: "When I bought my car, I knew that I didn't want to be stuck with a car loan for the next 5-6 years. I made a plan to pay off my car loan as quickly as possible, and I stuck to it. I cut back on expenses, worked overtime, and even picked up a side hustle to bring in extra income. Within 18 months, I was able to make my final car payment and become debt-free. It was a huge accomplishment and a weight off my shoulders.",
      summary: "Learn how one person was able to pay off their car loan in record time and the strategies they used.",
      tags: ["loans", "debt repayment"],
      date: "2022-06-15",
      imgsource: Img1,
    },
    {
      title: "The Benefits of Automating Your Savings",
      blogpost: "Saving money is hard, but it's even harder when you have to manually transfer money into your savings account each month. That's why I started automating my savings. I set up an automatic transfer from my checking account to my savings account each month. This way, I don't have to think about it, and I don't miss the money. Automating my savings has helped me reach my financial goals faster and with less stress.",
      summary: "Learn about the benefits of automating your savings and how it can help you reach your financial goals.",
      tags: ["saving", "technology"],
      date: "2022-07-10",
      imgsource: Img2,
    },
    {
      title: "The Future of Finance: How Blockchain Technology is Changing the Game",
      blogpost: "Blockchain technology has the potential to revolutionize the financial industry. It's a decentralized ledger that allows for secure and transparent transactions without the need for intermediaries like banks. With blockchain, transactions can be completed faster and with lower fees. It also has the potential to reduce fraud and increase transparency. While there are still challenges to be addressed, the potential benefits of blockchain in finance are exciting.",
      summary: "Learn about how blockchain technology is changing the game in the financial industry and what the future of finance could look like.",
      tags: ["technology", "finance", "cryptocurrency"],
      date: "2022-08-05",
      imgsource: Img3,
    },
    {
      title: "How to Build a Strong Credit Score",
      blogpost: "Your credit score is an important part of your financial health. It's used by lenders to determine your creditworthiness and can impact your ability to get approved for loans or credit cards. Building a strong credit score takes time and effort. You need to make sure you're making on-time payments, keeping your credit utilization low, and not applying for too much credit at once. It's also important to regularly check your credit report for errors and address them promptly.",
      summary: "Learn about the steps you can take to build a strong credit score and improve your financial health.",
      tags: ["finance", "credit score"],
      date: "2022-09-20",
      imgsource: Img1,
    },
    {
        title: "Investing in Cryptocurrency: A Beginner's Guide",
        blogpost: "Cryptocurrency has become a popular investment option in recent years, but it can be intimidating for beginners. Before investing in cryptocurrency, it's important to do your research and understand the risks involved. You should also start small and only invest what you can afford to lose. It's also important to have a plan for when to buy and sell, as well as to keep your investments diversified.",
        summary: "Learn about the basics of investing in cryptocurrency and how to get started as a beginner.",
        tags: ["cryptocurrency", "investing", "finance"],
        date: "2022-10-10",
        imgsource: Img2,
    },
    {
        title: "The Power of Compound Interest: How It Can Help You Build Wealth",
        blogpost: "Compound interest is a powerful tool that can help you build wealth over time. When you invest money, you earn interest not only on your initial investment but also on the interest you earn. This means your money can grow exponentially over time. To take advantage of compound interest, it's important to start investing early and consistently. Even small amounts can make a big difference over time.",
        summary: "Learn about the power of compound interest and how it can help you build wealth over time.",
        tags: ["finance", "investing"],
        date: "2022-11-01",
        imgsource: Img2,
    },
    {
        title: "How to Secure Your Online Banking",
        blogpost: "Online banking is convenient, but it also comes with security risks. To protect your account, it's important to use a strong and unique password, enable two-factor authentication, and avoid using public Wi-Fi when accessing your account. You should also regularly check your account for any unauthorized transactions and report them immediately.",
        summary: "Learn about the steps you can take to secure your online banking and protect your account from fraud.",
        tags: ["security", "finance"],
        date: "2022-11-15",
        imgsource: Img3,
      },
      {
        title: "The Importance of Cybersecurity in Banking",
        blogpost: "Cybersecurity is a critical issue for banks and their customers. Banks are responsible for safeguarding their customers' personal and financial information, while customers need to take steps to protect their own information as well. This includes using strong passwords, regularly checking account activity, and being cautious when clicking on links or downloading attachments in emails.",
        summary: "Learn about why cybersecurity is important in banking and what you can do to protect your personal and financial information.",
        tags: ["security", "finance", "technology"],
        date: "2022-12-01",
        imgsource: Img1,
      },
      {
        title: "Investing in Security Stocks: A Smart Choice for Your Portfolio",
        blogpost: "As the world becomes more connected and reliant on technology, the demand for security solutions is increasing. Investing in security stocks can be a smart choice for your portfolio, as these companies are likely to experience growth in the coming years. Some examples of security stocks include cybersecurity companies, surveillance companies, and defense contractors.",
        summary: "Learn about why investing in security stocks can be a smart choice for your portfolio and what types of companies to look for.",
        tags: ["investing", "security", "finance"],
        date: "2023-01-10",
        imgsource: Img2,
      },
      {
        title: "The Rise of Biometric Security in Banking",
        blogpost: "Biometric security, such as fingerprint or facial recognition technology, is becoming increasingly common in banking. This technology provides an extra layer of security and convenience for customers. Instead of remembering a password or carrying a physical token, customers can use their unique biometric data to access their accounts. While this technology is not foolproof, it can be an effective way to prevent fraud and protect sensitive information.",
        summary: "Learn about the rise of biometric security in banking and how it can provide an extra layer of security and convenience for customers.",
        tags: ["security", "finance", "technology"],
        date: "2023-02-05",
        imgsource: Img3,
      },
      {
        title: "The Benefits of Investing in a Pension Plan",
        blogpost: "A pension plan is a type of retirement plan that provides a steady income in retirement. By investing in a pension plan, you can ensure that you have a source of income to rely on in your golden years. Pension plans also offer tax advantages and may provide additional benefits such as life insurance or disability coverage.",
        summary: "Learn about the benefits of investing in a pension plan and how it can help you prepare for retirement.",
        tags: ["pension", "investing", "finance"],
        date: "2023-03-01",
        imgsource: Img1,
      },
      {
        title: "How to Choose Stocks for Your Investment Portfolio",
        blogpost: "Choosing the right stocks for your investment portfolio can be challenging, but it's important to do your research and make informed decisions. Consider factors such as the company's financial health, growth potential, and valuation. It's also a good idea to diversify your portfolio by investing in stocks from different industries and regions.",
        summary: "Learn about the factors to consider when choosing stocks for your investment portfolio and how to diversify your holdings.",
        tags: ["stocks", "investing", "finance"],
        date: "2023-03-15",
        imgsource: Img2,
      },
      {
        title: "Understanding the Basics of Personal Finance",
        blogpost: "Personal finance is the management of your money and financial decisions on a personal level. It includes budgeting, saving, investing, and managing debt. By understanding the basics of personal finance, you can make informed decisions about your money and build a strong financial foundation for your future.",
        summary: "Learn about the basics of personal finance and how you can make informed decisions about your money.",
        tags: ["finance"],
        date: "2023-04-01",
        imgsource: Img3,
      }
]

export default BlogData;

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RiMenuUnfoldFill, RiFolderTransferFill } from 'react-icons/ri'
import { AiFillDashboard } from 'react-icons/ai'
import { MdContactSupport } from 'react-icons/md'
import { IoLogOut } from 'react-icons/io5'

function Sidebar(props) {

  const navigate = useNavigate()

  return (
    <div className='home__sidebar'>
      <div className="home__sidebar__iconbox">
        <RiMenuUnfoldFill className="home__sidebar__icon" />
      </div>

      <ul className="home__sidebar__list">
        <li
          onClick={() => props.setPage(0)}
          className={props.page === 0 ? "home__sidebar__list--selected" : "" }
        >
          <AiFillDashboard className='home__sidebar__list__icon' />
          <span>Dashboard</span>
        </li>

        <li
          onClick={() => props.setPage(1)}
          className={props.page === 1 ? "home__sidebar__list--selected" : "" }
        >
          <RiFolderTransferFill className='home__sidebar__list__icon' />
          <span>Transfer</span>
        </li>

        <li
          onClick={() => props.setPage(2)}
          className={props.page === 2 ? "home__sidebar__list--selected" : "" }
        >
          <MdContactSupport className='home__sidebar__list__icon' />
          <span>Support</span>
        </li>
      </ul>

      <div 
        className="home__sidebar__logout"
        onClick={() => navigate('/')}
      >
        <IoLogOut className="home__sidebar__logout__icon" />
        <p>Logout</p>
      </div>
    </div>
  )
}

export default Sidebar
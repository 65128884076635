import React, { useEffect } from 'react'
import { Footer, Header } from '../../_shared';
import Landing from './Landing';

function Blog() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
        <Header />
        <Landing />
        <Footer />
    </div>
  )
}

export default Blog;
import React from 'react'
import CardGold from '../../img/CardGold.svg';
import CardGreen from '../../img/CardGreenB.svg';
import CardBlack from '../../img/CardBlackB.svg';

const cardData = [
    {
        name: 'Basic',
        price: 'Free',
        description: 'For single individuals such as students and managing parts of a company structure'
    },
    {
        name: 'Premium',
        price: '5$',
        description: 'For small scale business and joint accounts for individuals whether couple or not'
    },
    {
        name: 'Gold',
        price: '10$',
        description: 'Suitable for large scale business and companies who  offer global services'
    }
]

function Cards() {
    return (
        <div className='ftrcards'>
            <div className="ftrcards__top">
                <h6 className="ftrlanding__tophead">Accounts</h6>
                <h2 className="ftrlanding__header">Choose Your Card</h2>
                <p className="ftrlanding__text">
                    Variety of  Cards with support for different services all along.
                    We support different lifestyles and give you the liberty to operate via diffferent regions.
                    Sign up now.
                </p>
            </div>
            <div className="ftrcards__container">
                <CardItem data={cardData[0]} hasHeaderTag img={CardGreen} />
                <CardItem data={cardData[1]} isPricedMonthly img={CardBlack} />
                <CardItem data={cardData[2]} isPricedMonthly img={CardGold} />
            </div>
        </div>
    )
}

const CardItem = (props) => {

    return (
        <div className="ftrcards__card">
            <div className="ftrcards__card__header">
                <h3 className="ftrcards__card__header__name">{props.data.name}</h3>
                <span
                    className="ftrcards__card__header__tag"
                    style={props.hasHeaderTag ? { display: 'inline-block', width: 'auto' } : {}}
                >
                    Popular
                </span>
            </div>
            <div className="ftrcards__card__plan">
                <h2 className="ftrcards__card__plan__name">{props.data.price}</h2>
                <span
                    style={props.isPricedMonthly ? {} : { display: 'none', width: '0' }}
                >
                    per Month
                </span>
            </div>

            <div className="ftrcards__card__description">
                {props.data.description}

            </div>

            <div className="ftrcards__card__imgbox">
                <img src={props.img} alt="Card Representated" className="ftrcards__card__img" />
            </div>

            <button className="ftrcards__card__btn btn">Get Started</button>

        </div>
    )
}

export default Cards
export { CardItem };
import React, { useEffect } from 'react'
import Landing from './Landing'
import { ContactUs, Footer, Header } from '../../_shared'
import FAQ from './FAQ'

function Support() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='support'>
      <Header />
      <Landing />
      <ContactUs />
      <FAQ />
      <Footer />
    </div>
  )
}

export default Support
import React from 'react'
import Home from '../../img/home.png';
import Car from '../../img/electric-car.png'
import Plane from '../../img/plane.png'
import Laptop from '../../img/laptop-screen.png'
import Grocery from '../../img/grocery-cart.png'

function Savings() {
    return (
        <div className='savings'>
            <h4 className="savings__tophead">Savings Account</h4>
            <h2 className="savings__header">Organize your money easily</h2>
            <div className="savings__textbox" data-aos="fade-up">
                <p className="savings__text">
                    "Our savings account offers a secure and convenient way to save for the future, with competitive interest rates,
                    flexible deposit options, and user-friendly online management tools."
                </p>
                <div className="savings__seeall">
                    <button className="btn savings__seeall__btn">All Features</button>
                </div>
            </div>
            
            <div className="savings__cardcontainer">

                <div className="savings__cardbox" data-aos="fade-up">
                    <div className="savings__card">
                        <img src={Laptop} alt="Card preview" className="savings__card__img" />
                    </div>
                    <div className="savings__card__textbox">
                        <p className="savings__card__textbox__name">New Laptop</p>
                        <p className="savings__card__textbox__price">&euro; 1390</p>
                    </div>
                </div>

                <div className="savings__cardbox" data-aos="fade-up">
                    <div className="savings__card">
                        <img src={Car} alt="Card preview" className="savings__card__img" />
                    </div>
                    <div className="savings__card__textbox">
                        <p className="savings__card__textbox__name">Dream Car</p>
                        <p className="savings__card__textbox__price">&euro; 80000</p>
                    </div>
                </div>

                <div className="savings__cardbox" data-aos="fade-up">
                    <div className="savings__card">
                        <img src={Plane} alt="Card preview" className="savings__card__img" />
                    </div>
                    <div className="savings__card__textbox">
                        <p className="savings__card__textbox__name">Holidays & Vacations</p>
                        <p className="savings__card__textbox__price">&euro; 790</p>
                    </div>
                </div>

                <div className="savings__cardbox" data-aos="fade-up">
                    <div className="savings__card">
                        <img src={Home} alt="Card preview" className="savings__card__img" />
                    </div>
                    <div className="savings__card__textbox">
                        <p className="savings__card__textbox__name">Luxury Homes</p>
                        <p className="savings__card__textbox__price">&euro; 59000</p>
                    </div>
                </div>

                <div className="savings__cardbox" data-aos="fade-up">
                    <div className="savings__card">
                        <img src={Grocery} alt="Card preview" className="savings__card__img" />
                    </div>
                    <div className="savings__card__textbox">
                        <p className="savings__card__textbox__name">Groceries</p>
                        <p className="savings__card__textbox__price">&euro; 390</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Savings
import React from 'react'
import { Header, Footer } from '../../_shared';
import { FaTwitter } from 'react-icons/fa';
import { BsFacebook, BsLinkedin } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { BlogItem } from '../Landing/Blog'
import { BlogData } from '../../_shared'

const myprops = {data: BlogData[4]}

function ReadBlog() {
    return (
        <div className='readblog'>
            <Header />
            <div className="readblog__tags">
                {
                    myprops.data.tags.map((tag) => (
                        <p className="readblog__tags__item">{tag}</p>
                    ))
                }
            </div>

            <h2 className="readblog__title">{myprops.data.title}</h2>
            <p className="readblog__summary">{myprops.data.summary}</p>

            <div className="readblog__imgbox">
                <img src={myprops.data.imgsource} alt="Representation of Blog in visual format" className="readblog__img" />
            </div>

            <div className="readblog__text">{myprops.data.blogpost}</div>

            <div className="readblog__share">
                <h4 className="readblog__share__header">Share Article</h4>
                <div className="readblog__share__iconbox">
                    <FaTwitter className='readblog__share__icon' />
                    <BsFacebook className='readblog__share__icon' />
                    <BsLinkedin className='readblog__share__icon' />
                    <AiFillInstagram className='readblog__share__icon' />
                </div>
            </div>

            <div className="readblog__articles">
                <div className="readblog__articles__header">
                    <h3 className="readblog__articles__header__main">Related Articles</h3>
                    <p className="readblog__articles__header__sub">All Articles &rarr;</p>
                </div>

                <div className="readblog__articles__body">
                    <BlogItem data={BlogData[0]} />
                    <BlogItem data={BlogData[1]} />
                    <BlogItem data={BlogData[2]} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ReadBlog;
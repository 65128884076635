import React from 'react'
import { HiPhone, HiMail } from 'react-icons/hi'

function ContactUs() {
    return (
        <div className='contactus'>
            <div className="contactus__item contactus__item--medium">
                <div className="contactus__item__textbox">
                    <h3 className="contactus__item__textbox__title">Still have Questions?</h3>
                    <p className="contactus__item__textbox__sub">We are here to help</p>
                </div>
            </div>

            <div className="contactus__item">

                <div className="contactus__item__subbox contactus__item__subbox__small">
                    <div className="contactus__item__channel">
                        <div className="contactus__item__channel__imgbox">
                            <HiPhone className='contactus__item__channel__img' />
                        </div>
                    </div>


                    <div className="contactus__item__channel__text">
                        <p className="contactus__item__channel__text--main">0031 176 123 438</p>
                        <p className="contactus__item__channel__text--sub">Support Hotline</p>
                    </div>
                </div>


                <div className="contactus__item__subbox contactus__item__subbox__big">

                    <div className="contactus__item__channel">
                        <div className="contactus__item__channel__imgbox">
                            <HiMail className='contactus__item__channel__img' />
                        </div>

                        <div className="contactus__item__channel__text">
                            <p className="contactus__item__channel__text--main">support@eindhoveninvestment.com</p>
                            <p className="contactus__item__channel__text--sub">Support Email</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="contactus__item contactus__item--small">
                <button className="btn contactus__btn">Chat with us</button>
            </div>


        </div>
    )
}

export default ContactUs
import React, { useEffect } from 'react'
import { Header, Footer } from '../../_shared'
import Login from './Login'

function FullLogin() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />
        <Login />
        <Footer />
    </div>
  )
}

export default FullLogin
import React from 'react';
import {BlogData} from '../../_shared'
import Blog1 from '../../img/jason-goodman-bzqU01v-G54-unsplash.jpg';
import Blog2 from '../../img/blake-wisz--JJg90OAnWI-unsplash.jpg';
import Blog3 from '../../img/pexels-pixabay-34761.jpg';


function Blog() {
  return (
    <div className='landingblog'>
        <div className="landingblog__headerbox">
            <h2 className="landingblog__header">Blog</h2>
            <div className="landingblog__seeall">
                <button className="btn landingblog__seeall__btn">All Articles</button>
            </div>
        </div>

        <div className="landingblog__content">
            <BlogItem data={BlogData[0]} />
            <BlogItem data={BlogData[1]} />
            <BlogItem data={BlogData[3]} />
        </div>

    </div>
  )
}

export const BlogItem = (props) => {
    return (
        <div className="landingblog__content__item" data-aos="fade-up">
            <div className="landingblog__content__item__imgbox">
                <img src={props.data.imgsource} alt="" className="landingblog__content__item__img" />
            </div>
            <h3 className="landingblog__content__item__title">{props.data.title}</h3>
            <p className="landingblog__content__item__text">{props.data.summary}</p>
            <p className="landingblog__content__item__date">{props.data.date}</p>
            <ul className="landingblog__content__item__tags">
                <li>Technology</li>
                <li>Product</li>
                <li>App</li>
            </ul>
        </div>
    )
}

export default Blog;
import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { AppFeatures } from './features/AppFeatures';
import { Landing } from './features/Landing';
import { Compare } from './features/Compare';
import { Support } from './features/Support';
import { Blog, ReadBlog } from './features/Blog';
import { FullLogin } from './features/Login'
import { Signup } from './features/Signup'
import { Home } from './features/Home'
import ProtectedRoute from './ProtectedRoutes';

export default function Navigator() {

    return (
        <Router>

            <Routes>

                <Route
                    path='/home'
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>}
                />

                <Route
                    path="/login"
                    element={
                        <FullLogin />
                    }
                />

                <Route
                    path="/signup"
                    element={
                        <Signup />
                    }
                />

                <Route
                    path="/readblog"
                    element={
                        <ReadBlog />
                    }
                />

                <Route
                    path="/blog"
                    element={
                        <Blog />
                    }
                />

                <Route
                    path="/support"
                    element={
                        <Support />
                    }
                />

                <Route
                    path="/features"
                    element={
                        <AppFeatures />
                    }
                />

                <Route
                    path="/compare"
                    element={
                        <Compare />
                    }
                />

                <Route path="/" element={<Landing />} />
            </Routes>

        </Router>
    )
}

import React from 'react'
import CardBlack from '../../img/CardBlack.svg';
import CardGreen from '../../img/CardGreen.svg';
import CardGold from '../../img/62fa8d2fe6c54d075cde81be_card-gold-rotated.svg';

function Landing() {
    return (
        <div className='cmplanding'>
            <div className="cmplanding__header" data-aos="fade-up">
                <h6 className="cmplanding__header__sub">Compare Cards</h6>
                <h2 className="cmplanding__header__main">The Ideal Card for you</h2>
            </div>

            <div className="cmplanding__cardbox" data-aos="fade-up">
                <div className="cmplanding__cardbox__imgcontainer">
                    <div className="cmplanding__card__imgbox cmplanding__card__imgbox--1">
                        <img src={CardBlack} alt="Item Representation" className="cmplanding__card__img" />
                    </div>

                    <div className="cmplanding__card__imgbox cmplanding__card__imgbox--2">
                        <img src={CardGold} alt="Item Representation" className="cmplanding__card__img " />
                    </div>

                    <div className="cmplanding__card__imgbox cmplanding__card__imgbox--3">
                        <img src={CardGreen} alt="Item Representation" className="cmplanding__card__img" />
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Landing
import React from 'react';
import { BlogItem } from '../Landing/Blog';
import { BlogData } from '../../_shared'

function Landing() {
    return (
        <div className='bloglanding'>
            <h2 className="bloglanding__header">Blog</h2>
            <div className="bloglanding__tags">
                <h6 className="bloglanding__tags__title">Categories</h6>
                <div className='bloglanding__tags__content'>
                    <p className="bloglanding__tags__item">App</p>
                    <p className="bloglanding__tags__item">Technology</p>
                    <p className="bloglanding__tags__item">Product</p>
                </div>
            </div>
            <div className="bloglanding__blogs">
                {
                    BlogData.map((blog) => (
                        <BlogItem data={blog} />
                    ))
                }
            </div>
        </div>
    )
}

export default Landing